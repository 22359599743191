// import React, { Component } from "react";
// import "./TabPortfolio.css";
// import { Button, Modal } from "react-bootstrap";
// import Slider from "react-slick";
// import PortfolioImages from "./PortfolioImages";
// import PortfolioImages2 from "./PortfolioImages2";
// import PortfolioImages3 from "./PortfolioImages3";
// import all_imgs from "./Imgs";
// import Image from "../../assets/Image";
// import "../../assets/Image";

// const filters = [
//   { name: "Android", status: false },
//   { name: "iOS", status: false },
//   { name: "Web Development", status: false },
// ];

// const Filters = ({ onClickAll, all, onClick, filters }) => (
//   <form>
//     <ul>
//       <li onClick={onClickAll}>
//         <input type="checkbox" checked={all} readOnly />
//         <label htmlFor="all">All</label>
//       </li>
//       {filters.map((filter, i) => (
//         <li key={i} data-index={i} onClick={onClick}>
//           <input
//             id={filter.name}
//             type="checkbox"
//             checked={filter.status}
//             readOnly
//           />
//           <label htmlFor={filter.name}>{filter.name}</label>
//         </li>
//       ))}
//     </ul>
//   </form>
// );

// const settings = {
//   // infinite: true,
//   mouseDrag: false,
//   dots: true,
//   // autoplay: true,
//   // slidesToShow: 1,
// };

// export default class TabPorfolio extends Component {
//   handleModal(index) {
//     this.setState({ show: !this.state.show, selectedImageIndex: index });
//   }
//   handleModelClick(index) {
//     this.setState({ parttwo: !this.state.parttwo, selectedImageIndex: index });
//   }
//   handleClick(index) {
//     this.setState({
//       partthree: !this.state.partthree,
//       selectedImageIndex: index,
//     });
//   }
//   state = {
//     imgs: [...all_imgs],
//     filters,
//     all: true,
//     selectedImageIndex: null,
//   };

//   Cards = (imgs) => {
//     return (
//       <ul>
//         {imgs.map((img, i) => (
//           <li key={img.id} onClick={() => this.handleModal(i)}>
//             <figure>
//               {img.tag === "Android" ? (
//                 <>
//                   <img src={img.src} alt={img.author} />
//                 </>
//               ) : img.tag === "iOS" ? (
//                 <>
//                   <img src={img.src} alt={img.author} />
//                 </>
//               ) : img.tag === "Web Development" ? (
//                 <>
//                   <img src={img.src} alt={img.author} />
//                 </>
//               ) : null}

//               <figcaption>
//                 <div>{img.author} </div>
//                 {/* <span>{img.tag}</span> */}
//                 {img.tag_obj.map((item, i) => (
//                   <span key={i}>{item} </span>
//                 ))}
//               </figcaption>
//             </figure>
//           </li>
//         ))}
//       </ul>
//     );
//   };

//   setFilter = (e) => {
//     e.preventDefault();
//     const { filters } = this.state;
//     const { index } = e.currentTarget.dataset;

//     // Set the selected filter to true and the others to false
//     filters.forEach((filter, i) => {
//       filter.status = i == index;
//     });

//     this.setState({
//       filters,
//     });
//     this.updateFilters();
//     this.updateImgs();
//   };

//   setAll = () => {
//     const { filters } = this.state;

//     // Set the selected filter to true and the others to false
//     filters.forEach((filter) => {
//       filter.status = filter.name == "All";
//     });

//     this.setState({
//       all: true,
//       filters,
//     });
//     // console.log("setAll--", this.state);
//     this.updateImgs(true);
//   };

//   updateFilters() {
//     const { filters, all } = this.state;
//     const allFiltersTrue = filters.every((filter) => filter.status === true);
//     const allFiltersFalse = filters.every((filter) => filter.status === false);

//     if (allFiltersTrue || (allFiltersFalse && filters[0].status)) {
//       this.setAll();
//     } else {
//       this.setState({
//         all: false,
//       });
//     }
//   }

//   updateImgs(isAll) {
//     const { filters, all } = this.state;
//     let newImgs = [];
//     let a = 0;
//     // console.log("update img s ", all, filters);
//     let filter_name = "";
//     filters.forEach((filter, filterKey) => {
//       if (filter.status) filter_name = filter.name;
//     });
//     all_imgs.forEach((img, imgKey) => {
//       if (img.tag_obj.includes(filter_name) || isAll) {
//         newImgs.push(img);
//       }
//     });

//     this.setState({
//       imgs: newImgs,
//     });
//   }

//   render() {
//     const { filters, all, selectedImageIndex } = this.state;

//     return (
//       <div className="portfolio_section">
//         <Filters
//           onClickAll={this.setAll}
//           all={all}
//           onClick={this.setFilter}
//           filters={filters}
//         />
//         {/* {all ? <Cards  imgs={imgs} /> : <Cards imgs={this.state.imgs} />} */}
//         {this.state.imgs ? this.Cards(this.state.imgs) : null}
//         {!isNaN(this.state.selectedImageIndex) && (
//           <Modal
//             size="xl"
//             aria-labelledby="contained-modal-title-vcenter"
//             show={this.state.show}
//             onHide={() => this.handleModal()}
//           >
//             <Modal.Header closeButton>
//               <div>
//                 <p>{this.state.imgs[this.state.selectedImageIndex]?.author}</p>
//               </div>
//             </Modal.Header>
//             <Modal.Body>
//               <div className="row">
//                 <div className="col-md-7">
//                   <Slider {...settings}>
//                     {this.state.imgs[this.state.selectedImageIndex]?.images.map(
//                       (image) => (
//                         <div key={image.id}>
//                           <img style={{ width: "100%" }} src={image.image} />
//                         </div>
//                       )
//                     )}
//                   </Slider>
//                 </div>
//                 <div className="col-md-5">
//                   <h4 className="text-4 fw-600">Project Info:</h4>
//                   <p>
//                     {this.state.imgs[this.state.selectedImageIndex]?.content}
//                   </p>
//                   <h4 className="text-4 fw-600 mt-4">Project Details: </h4>
//                   <ul className="list-style-2 ">
//                     <li>
//                       <span className="text-dark fw-600 me-2">Industry:</span>
//                       {this.state.imgs[this.state.selectedImageIndex]?.Industry}
//                     </li>
//                     <li>
//                       <span className="text-dark fw-600 me-2">
//                         Technologies:
//                       </span>
//                       {
//                         this.state.imgs[this.state.selectedImageIndex]
//                           ?.technologies
//                       }
//                     </li>
//                     <li>
//                       <span className="text-dark fw-600 me-2">Platform:</span>
//                       {this.state.imgs[this.state.selectedImageIndex]?.Platform}
//                     </li>
//                     {this.state.imgs[this.state.selectedImageIndex]?.tag ===
//                     "Web Development" ? (
//                       <>
//                         <li>
//                           <span class="text-dark fw-600 me-2">
//                             Website Link:
//                           </span>
//                           <p class="portfolio_url">
//                             <a target="_blank"
//                               href={
//                                 this.state.imgs[this.state.selectedImageIndex]
//                                   ?.url
//                               }
//                             >
//                               {
//                                 this.state.imgs[this.state.selectedImageIndex]
//                                   ?.url
//                               }
//                             </a>

//                             <i class="fas fa-external-link-alt ms-1"></i>
//                           </p>
//                         </li>
//                       </>
//                     ) : (
//                       <li>
//                         <span className="text-dark fw-600 me-2">
//                           Store Link:
//                         </span>
//                         <p className="portfolio_url">
//                           {this.state.imgs[this.state.selectedImageIndex]
//                             ?.Android_url ? (
//                               <a target="_blank"
//                               href={
//                                 this.state.imgs[this.state.selectedImageIndex]
//                                   ?.Android_url
//                               }
//                             >
//                               <img src={Image.PlayStore} />{" "}
//                             </a>
//                           ) : null}
//                           {/* <a
//                             href={
//                               this.state.imgs[this.state.selectedImageIndex]
//                                 ?.Android_url
//                             }
//                           >
//                             <img src={Image.PlayStore} />{" "}
//                           </a> */}

//                           {this.state.imgs[this.state.selectedImageIndex]
//                             ?.Ios_url ? (
//                             <a target="_blank"
//                               href={
//                                 this.state.imgs[this.state.selectedImageIndex]
//                                   ?.Ios_url
//                               }
//                             >
//                               <img src={Image.appleStore} />{" "}
//                             </a>
//                           ) : null}

//                           {/* <i className="fas fa-external-link-alt ms-1"></i> */}
//                         </p>
//                       </li>
//                     )}
//                   </ul>
//                 </div>
//               </div>
//             </Modal.Body>
//           </Modal>
//         )}
//       </div>
//     );
//   }
// }


import React, { Component } from "react";
import "./TabPortfolio.css";
import { Button, Modal } from "react-bootstrap";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import PortfolioImages from "./PortfolioImages";
import PortfolioImages2 from "./PortfolioImages2";
import PortfolioImages3 from "./PortfolioImages3";
import all_imgs from "./Imgs";
import Image from "../../assets/Image";
import "../../assets/Image";

const filters = [
  { name: "Android", status: false },
  { name: "iOS", status: false },
  { name: "Web Development", status: false },
];

const Filters = ({ onClickAll, all, onClick, filters }) => (
  <form>
    <ul>
      <li onClick={onClickAll}>
        <input type="checkbox" checked={all} readOnly />
        <label htmlFor="all">All</label>
      </li>
      {filters.map((filter, i) => (
        <li key={i} data-index={i} onClick={onClick}>
          <input
            id={filter.name}
            type="checkbox"
            checked={filter.status}
            readOnly
          />
          <label htmlFor={filter.name}>{filter.name}</label>
        </li>
      ))}
    </ul>
  </form>
);

const settings = {
  // infinite: true,
  mouseDrag: false,
  dots: true,
  arrows: false,
  duration: false,
  transitionDuration:false,
  // autoplay: true,
  // slidesToShow: 1,
};


export default class TabPorfolio extends Component {
  handleModal(index) {
    this.setState({ show: !this.state.show, selectedImageIndex: index });
  }
  handleModelClick(index) {
    this.setState({ parttwo: !this.state.parttwo, selectedImageIndex: index });
  }
  handleClick(index) {
    this.setState({
      partthree: !this.state.partthree,
      selectedImageIndex: index,
    });
  }
  state = {
    imgs: [...all_imgs],
    filters,
    all: true,
    selectedImageIndex: null,
  };

  Cards = (imgs) => {
    return (
      <ul>
        {imgs.map((img, i) => (
          <li key={img.id} onClick={() => this.handleModal(i)}>
            <figure>
              {img.tag === "Android" ? (
                <>
                  <img src={img.src} alt={img.author} />
                </>
              ) : img.tag === "iOS" ? (
                <>
                  <img src={img.src} alt={img.author} />
                </>
              ) : img.tag === "Web Development" ? (
                <>
                  <img src={img.src} alt={img.author} />
                </>
              ) : null}

              <figcaption>
                <div>{img.author} </div>
                {img.tag_obj.map((item, i) => (
                  <span key={i}>{item} </span>
                ))}
              </figcaption>
            </figure>
          </li>
        ))}
      </ul>
    );
  };

  setFilter = (e) => {
    e.preventDefault();
    const { filters } = this.state;
    const { index } = e.currentTarget.dataset;

    filters.forEach((filter, i) => {
      filter.status = i == index;
    });

    this.setState({
      filters,
    });
    this.updateFilters();
    this.updateImgs();
  };

  setAll = () => {
    const { filters } = this.state;

    filters.forEach((filter) => {
      filter.status = filter.name == "All";
    });

    this.setState({
      all: true,
      filters,
    });
    this.updateImgs(true);
  };

  updateFilters() {
    const { filters, all } = this.state;
    const allFiltersTrue = filters.every((filter) => filter.status === true);
    const allFiltersFalse = filters.every((filter) => filter.status === false);

    if (allFiltersTrue || (allFiltersFalse && filters[0].status)) {
      this.setAll();
    } else {
      this.setState({
        all: false,
      });
    }
  }

  updateImgs(isAll) {
    const { filters, all } = this.state;
    let newImgs = [];

    let filter_name = "";
    filters.forEach((filter, filterKey) => {
      if (filter.status) filter_name = filter.name;
    });
    all_imgs.forEach((img, imgKey) => {
      if (img.tag_obj.includes(filter_name) || isAll) {
        newImgs.push(img);
      }
    });

    this.setState({
      imgs: newImgs,
    });
  }

  render() {
    const { filters, all, selectedImageIndex } = this.state;

    return (
      <div className="portfolio_section">
        <Filters
          onClickAll={this.setAll}
          all={all}
          onClick={this.setFilter}
          filters={filters}
        />
        {this.state.imgs ? this.Cards(this.state.imgs) : null}
        {!isNaN(this.state.selectedImageIndex) && (
          <Modal
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            show={this.state.show}
            onHide={() => this.handleModal()}
          >
            <Modal.Header closeButton>
              <div>
                <p>{this.state.imgs[this.state.selectedImageIndex]?.author}</p>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-7">
                  <Slide {...settings}>
                    {this.state.imgs[this.state.selectedImageIndex]?.images.map(
                      (image) => (
                        <div key={image.id}>
                          <img style={{ width: "100%" }} src={image.image} />
                        </div>
                      )
                    )}
                  </Slide>
                </div>
                <div className="col-md-5">
                  <h4 className="text-4 fw-600">Project Info:</h4>
                  <p>
                    {this.state.imgs[this.state.selectedImageIndex]?.content}
                  </p>
                  <h4 className="text-4 fw-600 mt-4">Project Details: </h4>
                  <ul className="list-style-2 ">
                    <li>
                      <span className="text-dark fw-600 me-2">Industry:</span>
                      {this.state.imgs[this.state.selectedImageIndex]?.Industry}
                    </li>
                    <li>
                      <span className="text-dark fw-600 me-2">
                        Technologies:
                      </span>
                      {
                        this.state.imgs[this.state.selectedImageIndex]
                          ?.technologies
                      }
                    </li>
                    <li>
                      <span className="text-dark fw-600 me-2">Platform:</span>
                      {this.state.imgs[this.state.selectedImageIndex]?.Platform}
                    </li>
                    {this.state.imgs[this.state.selectedImageIndex]?.tag ===
                    "Web Development" ? (
                      <>
                        <li>
                          <span class="text-dark fw-600 me-2">
                            Website Link:
                          </span>
                          <p class="portfolio_url">
                            <a target="_blank"
                              href={
                                this.state.imgs[this.state.selectedImageIndex]
                                  ?.url
                              }
                            >
                              {
                                this.state.imgs[this.state.selectedImageIndex]
                                  ?.url
                              }
                            </a>

                            <i class="fas fa-external-link-alt ms-1"></i>
                          </p>
                        </li>
                      </>
                    ) : (
                      <li>
                        <span className="text-dark fw-600 me-2">
                          Store Link:
                        </span>
                        <p className="portfolio_url">
                          {this.state.imgs[this.state.selectedImageIndex]
                            ?.Android_url ? (
                              <a target="_blank"
                              href={
                                this.state.imgs[this.state.selectedImageIndex]
                                  ?.Android_url
                              }
                            >
                              <img src={Image.PlayStore} />{" "}
                            </a>
                          ) : null}
                          {this.state.imgs[this.state.selectedImageIndex]
                            ?.Ios_url ? (
                            <a target="_blank"
                              href={
                                this.state.imgs[this.state.selectedImageIndex]
                                  ?.Ios_url
                              }
                            >
                              <img src={Image.appleStore} />{" "}
                            </a>
                          ) : null}
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    );
  }
}

import { styled } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "../../assets/css/style.css";
import Image from "../../assets/Image";
import WOW from "wowjs";
import AutoPlay from "./carousel";
import SwipeToSlide from "./ClientSlider";
import Technology from "../Technologies";
import WeOffer from "../WeOffer";
import Testimonialnew from "../testimonialnew";
import Achievements from "./Achievements";
import Heading from "./Heading";
import axios from "axios";
import { logPageView } from "../GoogleAnalytics";

const Home = () => {
  useEffect(() => {
    logPageView();
  }, []);

  // const [text, setText] = useState("");
  // const [fullText, setFullText] = useState(
  //   ` ${"Start your digital journey"}  by building user-friendly mobile applications & websites. `
  // );
  // const [index, setIndex] = useState(0);

  // useEffect(() => {
    
  //   if (index < fullText.length) {
  //     setTimeout(() => {
  //       setText(text + fullText[index]);
  //       setIndex(index + 1);
  //     }, 40);
  //   }
    

  //   // new WOW.WOW().init();
  // }, [index]);



  // useEffect(() => {
  //   getData();
  //   console.log("useEffect call");
  // }, []);

  return (
    <>
      <div>
        <section className="header_bg">
          <div className="pt-150"></div>
          <section className="main_header_wrapper">
            <div className="container-fluid custome_container">
              <div className="row align-items-center">
                <div className="col-lg-6 ">
                  <div className="banner_info_wrapper">
                    <div className="ost-hero_banner_text">
                      <Heading></Heading>
                      <p>We can take you from concept to completion. </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="hero_banner_image">
                    <img
                      className="img-responsive"
                      // src={Image.herodeviceImage}
                      src={Image.deviceImg}
                      alt="banner image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="static-banner-images">
              <div className="shape-circle-1"></div>
            </div>

            <div className="animated-banner-images">
              <div className="animation-shape_shape-ring animation--rotating-diagonal">
                <div></div>
              </div>
              <div className="animation-shape-shape-circle  animation--clockwise">
                <div></div>
              </div>
            </div>
          </section>
        </section>
        <WeOffer> </WeOffer>
        <Achievements></Achievements>

        <Testimonialnew from={"home"}></Testimonialnew>
        <section className="our-clients-section">
          <div className="container">
            <div className="our-clients-body">
              <div className="title-body  animate__animated animate__fadeInUp animate__slower">
                <h2 className="all-title">Our clients</h2>
              </div>
              <div className="our-clients-row  animate__animated animate__zoomIn animate__slower">
                <SwipeToSlide />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;

import React, { useEffect } from "react";
import "../../assets/css/style.css";
import Image from "../../assets/Image";
import WOW from "wowjs";

const Services = () => {
  useEffect(() => {
    new WOW.WOW().init();
  });
  return (
    <>
      <section className="appservices-section d-flex">
        <div className="appservices-body">
          <div className="appservices-text">
            <h1>Services</h1>
            {/* <p>Home . Services<span> . App Development</span></p> */}
            <p>
              <span>App Development</span>
            </p>
          </div>
        </div>
      </section>
      <section className="industrial-work app-development-service">
        <div className="container">
          <div className="row border-bottom">
            <div className="col-lg-7 col-md-12 ">
              <div className="industrial-body">
                {/* <h6 className="title-clr">Welcome to osiyatech</h6> */}
                <h2 className="all-title">Mobile App Development</h2>
                {/* <div className="underline">
                  <p className="circle-line"></p>
                </div> */}
                <p className="sub-para">
                "Be visible to your customers at all times. Building your digital presence is the key to keeping your users and visitors glued to your business for a long time!"
                </p>
                <p className="text">
                If you are looking for the highest quality Native Mobile App Development, Osiyatech is your go-to partner. We are dedicated to providing a robust and engaging app experience. Beginning with technologies that supported a native app development approach, we have now integrated technologies such as Flutter and Iconic to develop reliable cross-platform applications and software. Our trained personnel will help you move your company forward by creating an effective digital presence through dependable and scalable applications.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 d-flex align-items-center justify-content-center">
              <div className="about-us-right-img ">
                <img src={Image.appDevServices} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us app-development-process">
        <div className="container">
          <div className="why-choose-us-title ">
            <h2 className="all-title">
              How osiyatech mobile app development process
            </h2>
            {/* <p className="sub-heading">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse.
            </p> */}
            {/* <div className="underline">
              <p className="circle-line"></p>
            </div> */}
          </div>
          <div className="services-icons">
            <div className="row">
              <div className="col-md-6">
                <div className="icons-col d-flex  ">
                  <div className="icon-box">
                    <img src={Image.serviceInfo} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Information Gathering</h5>
                    <p>
                    Analyze the business objective, understand what the application requires and plan to implement it.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex  ">
                  <div className="icon-box">
                    <img src={Image.serviceDesigning} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Designing</h5>
                    <p>
                    Develop our plans and design layout for our clients so the final output suits their requirements and caters for a superior user experience.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex  ">
                  <div className="icon-box">
                    <img src={Image.serviceDevelopment} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Development</h5>
                    <p>
                    As our developers work on the designs from the previous stage, they bring them to life. It's the most crucial step in client-side and server-side development.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex  ">
                  <div className="icon-box">
                    <img src={Image.serviceTesting} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Testing</h5>
                    <p>
                    After development, testing is important to ensure nothing is broken. We follow current development standards, check for speed, screen tests and cross-browser compatibility with our code.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex  ">
                  <div className="icon-box">
                    <img src={Image.serviceLaunching} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Launching</h5>
                    <p>
                    After successful testing, the application is deployed so the customer can use it themselves.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex">
                  <div className="icon-box">
                    <img src={Image.serviceMaintainance} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Maintenance</h5>
                    <p>
                    Launching the application does not imply the end of services. Post-development support is given for any issue received from the customer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why-choose-us trending-technology ">
        <div className="container">
          <div className="why-choose-us-title">
            <h2 className="all-title">Trending technologies we work on</h2>
            {/* <p className="sub-heading">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse.
            </p> */}
            {/* <div className="underline">
              <p className="circle-line"></p>
            </div> */}
          </div>
          <div className="services-icons">
            <div className="row">
              <div className="col-md-6">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.Android} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Android</h5>
                    <p>
                    At Osiyatech we have 10+ experience workers creating applications for customers, we give end to end solutions including eCommerce, Social App, Learning App, Games App, AR App, NFC Tag Development.{" "}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.iOS} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>iOS</h5>
                    <p>
                      Hire iPhone app developer to experience the luxury. Being
                      a top iphone app development company across the globe we
                      provide the best solutions at budget-friendly rates.
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.ionic} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Ionic</h5>
                    <p>
                    Hire developers to work on Ionic empowering them to build cross-platform mobile apps and Progressive Web Apps in various languages using a single shared codebase.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.reactNative} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>React Native</h5>
                    <p>
                    Hire react js developers for Osiyatech to develop agile applications with amazing UI/UX. Osiyatech is a react.js development firm that guarantees quality and value for money.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.swift} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Swift</h5>
                    <p>
                    Hire Swift developers to support native iOS app development. It's designed to be safer than C-based programming languages & eliminates low-quality code.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.objectiveImage} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Objective-C</h5>
                    <p>
                    Hire Objective-C developers to support native iOS app and OS X development. It is a high-level general-purpose, object-oriented programming language. eliminates low-quality code.{" "}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 ">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.crossPlatform} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Cross-Platform</h5>
                    <p>
                      Hire angular.js developer from Osiyatech to build apps
                      using angular equips, dynamic SPA, data linking, etc.
                      Osiyatech is angular js development company that offers
                      next-gen development solutions.
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.img_005} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Flutter</h5>
                    <p>
                    Hire Flutter developers from Osiyatech to build hybrid applications with amazing UI/UX on multi-platforms like Android, IOS, and the web. Osiyatech is an award-winning Flutter development company that assures quality and value for money and time.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img  style={{width: '100px', marginLeft:'-10px'}}  src={Image.SharePointImage} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>SharePoint</h5>
                    <p>
                    Hire our developers to build your website on SharePoint specially great for enterprises and companies who want custom workflows integrated into their site.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
     
    </>
  );
};

export default Services;

import { styled } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "../../assets/css/style.css";

const Heading = () => {
  const [text, setText] = useState("");
  const [fullText, setFullText] = useState(
    ` ${"Start your digital journey"}  by building user-friendly mobile applications & websites. `
  );
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < fullText.length) {
      setTimeout(() => {
        setText(text + fullText[index]);
        setIndex(index + 1);
      }, 80);
    }
  }, [index]);

  return (
    <>
      <h1>{text}</h1>
    </>
  );
};

export default Heading;

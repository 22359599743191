import React, {Component} from 'react';
import { Map, GoogleApiWrapper,Marker } from 'google-maps-react';
const mapStyles = {
  width: '100%',
  height:'100%'
};
class _MapContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stores: [{lat: 22.726447183142366, lng: -122.14184416996333},
              {latitude: 47.359423, longitude: -122.021071},
              {latitude: 75.8825524274587, longitude: -121.988426208496},
              {latitude: 47.6307081, longitude: -122.1434325},
              {latitude: 47.3084488, longitude: -122.2140121},
              {latitude: 47.5524695, longitude: -122.0425407}]
       
       
    }
  }

  displayMarkers = () => {
    return this.state.stores.map((store, index) => {
      return <Marker key={index} id={index} position={{
       lat: store.latitude,
       lng: store.longitude
     }}
     onClick={() => console.log("You clicked me!")} />
    })
  }

  render() {
    return (
      <div className='maparea'>
        <Map
          google={this.props.google}
          zoom={8}
          style={mapStyles}
          initialCenter={{ lat: 47.444, lng: -122.176}}
        >
          {this.displayMarkers()}
        </Map>
        </div>
    );
  }
}

export const MapContainer = GoogleApiWrapper({
  apiKey: ("AIzaSyCo2237ZA88fYPi8OBVxr1VfoQjx2WBrLM")
})(_MapContainer);


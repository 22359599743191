

import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'

export default function App() {

  return (
      <FloatingWhatsApp 
      
      phoneNumber="7746004294"
      accountName="Osiyatech"
      avatar='/fav-icon.png'
      chatMessage={"Hello there! 🤝 \n How may I assist you?"}
      statusMessage="Typically replies within 5 minutes"
      />
  )
}


//  useing for Whatsapp chate
import React from "react";
import Image from "../assets/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckIcon from "@mui/icons-material/Check";
import { Helmet } from "react-helmet";

function WeOffer() {
  
  return (
    <>
        <div>
      <Helmet>
        <title>Osiyatech-Home</title>
        <meta name="Home" content="This is the Home page." />
      
      </Helmet>
 
    </div>
      <div className="about__area section-padding">
        <div className="container">
          <div className="row align-items-center who-we">
            <div className="col-xl-6 col-lg-6 lg-mb-30">
              <div className="about__area-left">
                <div className="about__area-left-image">
                  <img
                    src={Image.aboutImage}
                    alt=""
                    className="img-responsive"
                  />
                  <div className="about__area-left-image-content">
                    {/* <div className="about__area-left-image-content-icon">
                      <img src={Image.awardImage} alt="" />
                    </div> */}
                    <h2 className="counter">
                      Make IT happen for your business.{" "}
                    </h2>
                    {/* <span> for your business. </span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <span className="subtitle-one">
             Who We Are{" "}
                </span>
           
                <div className="service_now_section">
                  <div className="service_naw_card mb-4">
                    <p>
                    Osiyatech is a world-class global IT Organization offering a wide range of end-to-end services and solutions in current and emerging technologies.
                    </p>
                  </div>
                  <div className="service_naw_card mb-4">
                    <p>
                    We at Osiyatech are committed to fostering effective working relationships with our business partners. Our team of skilled developers will combine your domain knowledge with our digital expertise to deliver your desired product. 
                    </p>
                  </div>
                </div>
              </div>
              <div className="banner_btn_section">
                    <a target="_blank"  href="/aboutus" className="btn_started">
                     About Us
                    </a>
                  </div>
            </div>
          </div>
           
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="about__area-right">
                <div className="about__area-right-title">
                  <span className="subtitle-one">What We Do</span>

                  <p>
                    Our company's skilled, experienced developers can help you
                    create everything from enterprise software to e-commerce web
                    & mobile applications.
                  </p>
                </div>

                <div className="about__area-right-list">
                  <h5>
                    {" "}
                    <i>
                      <CheckIcon />
                    </i>
                   Website Development & Designing
                  </h5>
                  <h5>
                    <i>
                      <CheckIcon />
                    </i>
                   Mobile Application Development
                  </h5>
                  <h5>
                    <i>
                      <CheckIcon />
                    </i>
                  API Integration
                  </h5>
                  <h5>
                    <i>
                      <CheckIcon />
                    </i>
                   Graphic Designing
                  </h5>
                  <h5>
                    <i>
                      <CheckIcon />
                    </i>
                   QA Testing
                  </h5>

                  <div className="banner_btn_section">
                    <a target="_blank" href="/All_Services" className="btn_started">
                      Explore Our Services
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="we-offer-img">
                <img
                  src={Image.serviceImage}
                  alt=""
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
          {/* <div className="pt-5"></div> */}
        </div>
      </div>
    </>
  );
}

export default WeOffer;


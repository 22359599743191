import React, { useEffect } from "react";
import "../../assets/css/style.css";
import Image from "../../assets/Image";
import WOW from "wowjs";
import Testimonialnew from "../testimonialnew";
import { Helmet } from "react-helmet";

const All_Services = () => {
  useEffect(() => {
    new WOW.WOW().init();
  });
  return (
    <>
           <div>
      <Helmet>
        <title>Osiyatech-Services</title>
        <meta name="Services" content="This is the Services page." />
 
      </Helmet>
 
    </div>
      <section className="appservices-section d-flex">
        <div className="appservices-body">
          <div className="appservices-text">
            <h1>Services</h1>
            {/* <p>Home . Services<span> . App Development</span></p> */}
            <p>
              <span>Our Services</span>
            </p>
          </div>
        </div>
      </section>
      <section className="industrial-work app-development-service">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 offset-lg-3">
              <div className="industrial-body">
                <h2 className="all-title text-center">
                Software And Services That Solve Your Problems
                </h2>
              </div>
            </div>
          
            <div className="col-lg-12">
              <div>
                <img
                  className="img-responsive desktopservice-banner"
                  src={Image.servicebanner}
                  alt="service banner"
                />
                 <img
                  className="img-responsive service-mobbanner"
                  src={Image.ServicesBannerImg}
                  alt="service banner"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us app-development-process">
        <div className="container">
          <div className="services-icons">
            <div className="row">
              {/* <div className="col-md-3 service-block-one">
                <div class="inner-box">
                  <div class="icon">
                    <img src={Image.serviceInfo} alt="" />
                  </div>
                  <h5> Web Designing </h5>
                  <div class="text">
                    <p>
                      Understanding what you want out of your application and
                      how do you plan to implement it.
                    </p>
                  </div>
                  <div class="link-btn">
                    <a
                      href="/webDevelopment"
                      class="theme-btn btn-style-one style-two"
                    >
                      <span>Learn More</span>
                    </a>
                  </div>
                </div>
              </div> */}
              <div className="col-md-4 service-block-one">
                <div class="inner-box">
                  <div class="icon">
                    <img src={Image.serviceDesigning} alt="" />
                  </div>
                  <h5> App Development </h5>
                  <div class="text">
                    <p>
                      Understanding what you want out of your application and
                      how do you plan to implement it.
                    </p>
                  </div>
                  <div class="link-btn">
                    <a
                      href="/services"
                      class="theme-btn btn-style-one style-two"
                    >
                      <span>Learn More</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 service-block-one">
                <div class="inner-box">
                  <div class="icon">
                    <img src={Image.serviceDevelopment} alt="" />
                  </div>
                  <h5>Web Development</h5>
                  <div class="text">
                    <p>
                      Understanding what you want out of your application and
                      how do you plan to implement it.
                    </p>
                  </div>
                  <div class="link-btn">
                    <a
                      href="/webDevelopment"
                      class="theme-btn btn-style-one style-two"
                    >
                      <span>Learn More</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 service-block-one">
                <div class="inner-box">
                  <div class="icon">
                    <img src={Image.serviceTesting} alt="" />
                  </div>
                  <h4>
                    <h5> API Integration </h5>
                  </h4>
                  <div class="text">
                    <p>
                      After development, we do the testing to assure the best
                      quality and bug-free website because CDN is bind.
                    </p>
                  </div>

                  <div class="link-btn">
                    <a
                      href="/payment"
                      class="theme-btn btn-style-one style-two"
                    >
                      <span>Learn More</span>
                    </a>
                  </div>
                  {/* 
                  <a href="#" className="icons-col  card-direction ">
                    <div className="icon-box"></div>
                    <div className=""></div>
                  </a> */}
                </div>
              </div>
              <div className="col-md-4 service-block-one">
                <div class="inner-box">
                  <div class="icon">
                    <img src={Image.serviceLaunching} alt="" />
                  </div>
                  <h5> Website Optimization </h5>
                  <div class="text">
                    <p>
                      After successful testing the application is delivered /
                      deployed to the customer for their use
                    </p>
                  </div>
                  <div class="link-btn">
                    <a
                      href="/websiteoptimization"
                      class="theme-btn btn-style-one style-two"
                    >
                      <span>Learn More</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 service-block-one">
                <div class="inner-box">
                  <div class="icon">
                    <img src={Image.serviceMaintainance} alt="" />
                  </div>
                  <h5> IT Solution </h5>
                  <div class="text">
                    <p>
                      It is an important step which makes sure that your
                      application works with efficiency all the time
                    </p>
                  </div>
                  <div class="link-btn">
                    <a
                      href="/itsolution"
                      class="theme-btn btn-style-one style-two"
                    >
                      <span>Learn More</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 service-block-one">
                <div class="inner-box">
                  <div class="icon">
                    <img src={Image.serviceMaintainance} alt="" />
                  </div>
                  <h5> QA Testing </h5>
                  <div class="text">
                    <p>
                      It is an important step which makes sure that your
                      application works with efficiency all the time
                    </p>
                    <div class="link-btn">
                      <a
                        href="/qatesting"
                        class="theme-btn btn-style-one style-two"
                      >
                        <span>Learn More</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 service-block-one">
                <div class="inner-box">
                  <div class="icon">
                    <img src={Image.serviceMaintainance} alt="" />
                  </div>
                  <h5> Cloud Computing </h5>
                  <div class="text">
                    <p>
                      It is an important step which makes sure that your
                      application works with efficiency all the time
                    </p>
                  </div>
                  <div class="link-btn">
                    <a
                      href="cockroach-control.html"
                      class="theme-btn btn-style-one style-two"
                    >
                      <span>Learn More</span>
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Testimonialnew from={'allservice'}></Testimonialnew>
    </>
  );
};

export default All_Services;

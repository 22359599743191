import React, { useEffect } from "react";
import NTabImage from "./nTabImage";
// import Image from "../../assets/Image";
import WOW from "wowjs";
import TabPortfolio from "./TabPorfolio"
import { Helmet } from "react-helmet";

const Portfolio = () => {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  //   useEffect(()=>{
  //     window.location.reload(false);
  // },[]);
  let b = 20;
  return (
    <>
     <div>
       <Helmet>
        <title>Osiyatech-Portfolios</title>
        <meta name="Portfolios" content="This is the Portfolios page." />
   
      </Helmet>
    </div>
      <div className="portfolio-page">
        <section className="about-us-section portfolio-section d-flex about-us-section-bg-img">
          <div className="about-us-body">
            <div className="aboutus-text">
              <h1>Portfolios</h1>
              {/* <p>Home.<span>Portfolio</span></p> */}
            </div>
          </div>
        </section>
        <section>
          {" "}
          <div className="why-choose-us-title py-3 wow animate__animated animate__fadeInUp animate__slower ">
            {/* <h2 className="all-title">Why should you join Osiyatech?</h2> */}
            {/* <p className="sub-heading">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis
              vel consequatur tempora atque blanditiis exercitationem incidunt,
              alias corporis quam assumenda dicta.
            </p>
            <div className="underline">
              <p className="circle-line"></p>
            </div> */}
          </div>
          {/* <NTabImage /> */}
          <TabPortfolio/>
        </section>
      </div>
    </>
  );
};

export default Portfolio;
// 
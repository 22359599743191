import React, { useEffect } from "react";
import "../../assets/css/style.css";
import Image from "../../assets/Image";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import WOW from "wowjs";

const WebsiteOptimization = () => {
  useEffect(() => {
    new WOW.WOW().init();
  });
  return (
    <>
        <section className="appservices-section d-flex">
        <div className="appservices-body">
          <div className="appservices-text">
            <h1>website optimization</h1>
            {/* <p>Home . Services<span> . IT Solution</span></p> */}
            <p>
              <span>IT Solution</span>
            </p>
          </div>
        </div>
      </section>
     <section className="industrial-work web-development-service">
        <div className="container">
          <div className="row border-bottom">
            <div className="col-lg-7 col-md-12">
              <div className="industrial-body">
               
                <h2 className="all-title">
                 
                </h2>
                {/* <div className='underline'>
              <p className='circle-line'></p>
              </div> */}
                <p className="text pt-4">
                  
                The primary objective of website optimization is to get more visitors and provide them with a smooth user experience, which results in a higher conversion rate for the business. Here conversions refer to the desired action of a visitor who completes a certain action on the website. So for an online store, you might want to optimize the website to encourage repeat purchases and checkouts, whereas, an online software company might want to improve the number of sign-ups & free trials.

                </p>
                <p className="text">
                For many businesses website traffic and conversions begin to decline over time, leading to a frustrating user experience. Optimization is crucial to address these shortcomings, improving search performance, traffic, and conversion rates. At Osiyatech, we conduct a complete audit of your website and identify its pain points. We do this by analyzing what works and what needs improvement. Our key considerations when optimizing a website are:

                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 d-flex align-items-center justify-content-center">
              <div className="about-us-right-img">
                <img src={Image.optimizeweb} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us web-development-process">
        <div className="container">
          <div className="why-choose-us-title ">
            {/* <p className="sub-heading">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse.
            </p> */}
            {/* <div className='underline'>
              <p className='circle-line'></p>
            </div> */}
          </div>
          <div className="website-optimization">
          <ul>
            <li>Technical SEO: Make it easy for search engines to find your content by improving your site's structure. </li>
            <li> Speed: Page speed is a critical factor that can skew the user experience one way or another. Setting up a CDN, leveraging browser caching or optimizing your images can speed it up.</li>
            <li>Mobile friendliness: Implement proper navigation and responsive designs and make it mobile-friendly. Not only will it help you reach a wider audience but it will help you with Google's mobile-first indexing. </li>
            <li>Accessibility: Make your site more accessible by following web accessibility best practices. Proper titles, headings, color contrast and renaming files can help a long way in making search engine bots find your pages faster. </li>
            <li> Web vitals: Improve your website's loading experience, interactivity, and visual stability, which are all essential metrics for a healthy website.
</li>
          </ul>

          </div>
        </div>
      </section>

      
     
    </>
  );
};

export default WebsiteOptimization;

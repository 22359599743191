import React from "react";
import "../../assets/css/style.css";
import Image from "../../assets/Image";

function Achievements() {
  return (
    <>
      <section className="ach_section_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <h3> Achievements </h3>
                <p>
                  The work we do ranges from defining the scope and goal of
                  projects, to deploying and maintaining meticulously designed
                  websites, web applications and web portals. Take a look at
                  some of our projects here:
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 mb-5"></div>
          <div className="row">
            <div className="col-md-4">
              <a
                href="https://mentorbooth.com/
"
                className="ach_img"
              >
                <img
                  className="img-responsive"
                  src={Image.achImg_02}
                  alt="achievement-image"
                />
              </a>
            </div>
            <div className="col-md-4">
              <a
                href="https://www.themiracleofstjoseph.org/"
                className="ach_img"
              >
                <img
                  className="img-responsive"
                  src={Image.achImg_01}
                  alt="achievement-image"
                />
              </a>
            </div>
            <div className="col-md-4">
              <a
                href="https://play.google.com/store/apps/details?id=com.app.mypeoplevote"
                className="ach_img"
              >
                <img
                  className="img-responsive"
                  src={Image.My_Prople}
                  alt="achievement-image"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Achievements;

import React, { useEffect } from "react";
import "../../assets/css/style.css";
import Image from "../../assets/Image";
import WOW from "wowjs";

const WebDevelopment = () => {
  useEffect(() => {
    new WOW.WOW().init();
  });
  return (
    <>
      <section className="appservices-section d-flex">
        <div className="appservices-body">
          <div className="appservices-text">
            <h1>Services</h1>
            {/* <p>Home . Services<span> . Web Development</span></p> */}
            <p>
              <span>Web Development</span>
            </p>
          </div>
        </div>
      </section>
      <section className="industrial-work web-development-service">
        <div className="container">
          <div className="row border-bottom">
            <div className="col-lg-7 col-md-12">
              <div className="industrial-body ">
                {/* <h6 className="title-clr">Welcome to osiyatech</h6> */}
                <h2 className="all-title">
                  Avail best in-class website design and development services
                </h2>
                {/* <div className="underline">
                  <p className="circle-line"></p>
                </div> */}
                <p className="text pt-4">
                Nowadays we are accustomed to getting everything in a matter of a few clicks. For a business to flourish it is crucial that it provides the same convenience to its people.
                </p>
                <p className="text">
                Having gained 10 years of faith, trust, credibility and reliance, Osiyatech is one of the top web app development companies on the market. With our best-in-class web application development services, we create functional and highly intuitive web applications to help you conduct your business more effectively. With highly qualified engineers, and over a decade of experience with different technologies our customized web application solution will be based on your company's and industry requirements.
                </p>
                <p className="text">
                So whether you want a customer-facing web app, B2B web app or employee portals there is no custom web development project we are not prepared for!
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 d-flex align-items-center justify-content-center">
              <div className="about-us-right-img  ">
                <img src={Image.webDevelopmentRight} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us web-development-process">
        <div className="container">
          <div className="why-choose-us-title  ">
            <h2 className="all-title">Osiyatech web development process</h2>
            {/* <p className="sub-heading">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse.
            </p> */}
            {/* <div className="underline">
              <p className="circle-line"></p>
            </div> */}
          </div>
          <div className="services-icons">
            <div className="row">
              <div className="col-md-6">
                <div className="icons-col d-flex ">
                  <div className="icon-box ">
                    <img src={Image.serviceInfo} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Understand the requirement:</h5>
                    <p>
                    Analyze the business objective, understand what the application requires and plan to implement it.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex">
                  <div className="icon-box">
                    <img src={Image.serviceDesigning} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Design plan</h5>
                    <p>
                    Develop our plans and design layout for our clients so the final output suits their requirements and caters for a superior user experience.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.serviceDevelopment} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Development</h5>
                    <p>
                    As our developers work on the designs from the previous stage, they bring them to life. It's the most crucial step in client-side and server-side development.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.serviceTesting} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Testing</h5>
                    <p>
                    After development, testing is important to ensure nothing is broken. We follow current development standards, check for speed, screen tests and cross-browser compatibility with our code.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.serviceLaunching} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Launching</h5>
                    <p>
                    After successful testing, the application is deployed so the customer can use it themselves.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex  ">
                  <div className="icon-box">
                    <img src={Image.serviceMaintainance} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Maintenance</h5>
                    <p>
                    Launching the application does not imply the end of services. Post-development support is given for any issue received from the customer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="web-design-development py-5">
        <div className="container">
          <div className="why-choose-us-title ">
            <h2 className="all-title mb-5">Web design & development</h2>
            {/* <p className="sub-heading">
              Providing exceptional and affordable designing and development
              services with cutting-edge technology.
            </p> */}
            {/* <div className='underline'>
                    <p className='circle-line'></p>
                </div> */}
          </div>
          <div className="we-are-expert">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="expert-img">
                  <img src={Image.webExpert} alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-12  ">
                <h4>We Are Experts In: </h4>
                <ol className="expert-list">
                  <li> Dynamic approach </li>
                  <li> Website redesigning </li>
                  <li> SEO friendly websites </li>
                  <li> Responsive websites </li>
                  <li> Custom web development services </li>
                  <li> Web app development services </li>
                  <li> Mobile friendly websites </li>
                  <li> Front end development </li>
                  <li> Prototyping </li>
                  <li> Hosting Services </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why-choose-us trending-technology ">
        <div className="container">
          <div className="why-choose-us-title ">
            <h2 className="all-title">Technologies we work on</h2>
            {/* <p className="sub-heading">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse.
            </p> */}
            {/* <div className="underline">
              <p className="circle-line"></p>
            </div> */}
          </div>
          <div className="services-icons">
            <div className="row">
              <div className="col-md-6">
                <div className="icons-col d-flex  ">
                  <div className="icon-box  box-icon">
                    <img src={Image.Python} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>PYTHON</h5>
                    <p>
                    Osiyatech is a leading Python development company. Hire a Python developer from us to receive unrivalled development solutions for game, app, or website development.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex ">
                  <div className="icon-box box-icon ">
                    <img src={Image.Wordpress} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Wordpress</h5>
                    <p>
                    Hire an Wordpress developer from Osiyatech to build apps using MySQL database, dynamic themes, Plugin's, data linking, etc. Osiyatech is an Wordpress development company offering next-gen development solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex  ">
                  <div className="icon-box box-icon">
                    <img src={Image.nodeJS} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Node JS</h5>
                    <p>
                    Osiyatech offers node.js professionals for all of your development needs, whether it's a node.js migration, new app creation, or development consultancy. Osiyatech is a trustworthy Node.js development firm.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex  ">
                  <div className="icon-box box-icon">
                    <img src={Image.reactJS} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>REACT.JS</h5>
                    <p>
                    Hire react.js developers from Osiyatech to develop agile methodology based applications with amazing UI/UX. Osiyatech is a react.js development firm that guarantees customer satisfaction along with  quality and value for money.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex  ">
                  <div className="icon-box box-icon">
                    <img src={Image.PHP} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>PHP</h5>
                    <p>
                    PHP is the most used programming language to create all types of websites. Hire a PHP developer from Osiyatech to achieve your predefined goals at budget-friendly rates. We use laravel , coordinator framework.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex  ">
                  <div className="icon-box box-icon">
                    <img src={Image.angularJs} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Angular Js</h5>
                    <p>
                    Hire an angular.js developer from Osiyatech to build apps using angular equips, dynamic SPA, data linking, etc. Osiyatech is an AngularJS development company that offers next-gen development solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex  ">
                  <div className="icon-box box-icon">
                    <img src={Image.dotNet} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>.Net</h5>
                    <p>
                    .NET is a free, cross-platform, open-source development platform that can be used to create a wide range of applications. With.NET, you may utilise a variety of languages, editors, and libraries to create applications for the web, mobile, desktop, gaming, IoT, and other platforms. We provide.Net developers for Web API, MVC, and AZURE technologies, We are a reliable .NET Development Company.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icons-col d-flex  ">
                  <div className="icon-box box-icon">
                    <img style={{width: '100px', marginLeft:'-10px'}} src={Image.SharePointImage} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>SharePoint</h5>
                    <p>
                    Hire SharePoint expert from Osiyatech for all your development need for modification of existing one or new SharePoint site development, we are a reliable SharePoint Dev Company.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WebDevelopment;

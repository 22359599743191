// This Component use for Google Analytics 
//( helps you gather essential information about how users interact with your web application. )


import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize('UA-99836300-1');
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

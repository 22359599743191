import React, { useEffect, useState } from "react";
import Image from "../../assets/Image";
import { detect } from "detect-browser";
import validator from "validator";
import { toast } from "react-toastify";
import axios from "axios";

const Footer = () => {
  const [email_address, setEmail] = useState("");
  const [ip_address, setIp_address] = useState("");
  const [country, setCountry] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [details, setDetails] = useState(null);
  const [errors, setErrors] = useState({});
  const isEmail = (email_address) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email_address);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        // setLat(pos.coords.latitude);
        // setLong(pos.coords.longitude);
      });
    }
  }, []);

  const getlocation = () => {
    window.open(
      "https://www.google.com/maps/place/21,+New+Palasia,+Indore,+Madhya+Pradesh+452001/@22.7264459,75.8826315,21z/data=!4m6!3m5!1s0x3962fd4752088da5:0x91844f7e117850b7!8m2!3d22.726425!4d75.882755!16s%2Fg%2F11c5mj9v0m"
    );
  };

  const getuserLocation = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.get(
        "https://geolocation-db.com/jsonp/a9e48c70-8b22-11ed-8d13-bd165d1291e3"
      );
      let location = response.data.replace("callback(", "").replace(")", "");
      // let location = response
      let location1 = JSON.parse(location);
      console.log("location details: ", location1);
      setLatitude(location1.latitude);
      setLongitude(location1.longitude);
      setCountry(location1.country_name);
      setIp_address(location1.IPv4);
      handleSubmit(location1);
    } catch (error) {
      console.log("allow location permission", e);
      // alert("allow location permission");
    }

    // .then((response) => response.json())
    // .then((data) => {
    //   setDetails(data)
    //   console.log("location details: ", data)
    //   // handleSubmit()
    // })
    // .catch((e) => {
    //   console.log("allow location permission", e);
    //   alert("allow location permission");
    // });
  };

  const handleSubmit = async (location1) => {
    const errors = {};
    if (!isEmail(email_address)) {
      errors.email_address = "Wrong email";
      toast.error(errors.email_address);
      setEmail("");
    }
 
    setErrors(errors);
 
    if (!Object.keys(errors).length) {
      // alert(JSON.stringify(email_address, null, 2));
      let item = {
        email_address,
        ip_address: location1.IPv4,
        country: location1.country_name,
        latitude: location1.latitude,
        longitude: location1.longitude,
      };
      // setLatitude(location1.latitude)
      // setLongitude(location1.longitude)
      // setCountry(location1.country_name)
      // setIp_address(location1.IPv4)
      try {
        const res = await axios.post(
          "https://osiyatech.com/apps/api/contact/subscriber_form",
          item
        );
        setEmail("");
        console.log(res);
      if(res){
        toast.success("Applied Success")
      }
      } catch (e) {
        console.log(e);
        if(e.response){
          toast.error(e.response.data.error)
        } else {
          toast.error("Something Went Wrong")
        }
        
      }
    }

  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  // const browser = detect();

  // if (!validator.isEmail(email)) {
  //   toast.error("Please enter a valid email");
  //   return;
  // }

  // const data = { email, lat, long };

  // if (browser) {
  //   data.browser = browser.name;
  //   data.browser_version = browser.version;
  //   data.os = browser.os;
  // }

  // axios
  //   .post(`${process.env.url}/news`, data)
  //   .then(() => {
  //     setEmail("");
  //     toast.success("Successfully subscribed to newsletter");
  //   })
  //   .catch((e) => console.log(e));
  // let data = {
  //   email_address,
  //   ip_address,
  //   country,
  //   latitude,
  //   longitude
  // }

  // try{
  //   const res = await axios.post('https://osiyatech.com/apps/api/contact/subscriber_form', data,)
  //   console.log(res);

  // }catch (e){
  //   console.log(e);
  // }

  //   };
  const date = "2023-04-15";
  const year = new Date(date).getFullYear();

  return (
    <>
      <section className="contact_info_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="contact_card">
                <img src={Image.usaFlag} alt="usaflag" />
                <div className="contact_info_div">
                  <h4> USA </h4>
                  <p>
                    {" "}
                    <a href="tel:+1.949.544.1199 ">+1.949.544.1199 </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="contact_card">
                <img src={Image.indiaFlag} alt="indiaflag" />
                <div>
                  <h4> INDIA </h4>
                  <p>
                    {" "}
                    <a href="tel:+91.774.600.4294">+91.774.600.4294</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="contact_card">
                <img src={Image.emailIcon} alt="email-icon" />
                <div>
                  <h4> Email </h4>
                  <p>
                    {" "}
                    <a href="mailto:adarsh@osiyatech.com ">
                      enquire@osiyatech.com{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-section">
        <div className="footer-body">
          <div className="container">
            <div className="footer-body-area">
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <div className="footer-col one">
                    <div className="footer-logo">
                      <img src={Image.footerLogo} />
                    </div>
                    <div className="footer-content">
                      <p>
                        Osiyatech is a world class global IT Organization
                        offering end-to-end services in various technologies.
                      </p>
                    </div>
                    <div className="footer-address">
                      <h6> Address </h6>
                      <p>
                        21, Chain singh ka bagicha, New palasia, Near Om
                        Namkeen, Indore (M.P.)
                      </p>
                      <div
                        className="d-flex align-items-start location-div"
                        onClick={getlocation}
                      >
                        <h6 className="location-text"> Location </h6>
                        <img
                          className="location-img"
                          src={Image.LocationImage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12">
                  <div className="footer-col">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="footer-sec-col">
                          {/* <div className="footer-country">
                            <p>USA</p>
                            <p>+1.949.544.1199</p>
                          </div> */}
                          <div className="footer-list">
                            <h6>Company</h6>
                            <a target="_blank" href="/aboutus"> About Us</a>
                            <a target="_blank" href="/portfolio"> Portfolio</a>
                            <a target="_blank" href="/contactus"> Contact Us</a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="footer-sec-col">
                          {/* <div className="footer-country">
                            <p>INDIA</p>
                            <p>+91.774.600.4294</p>
                          </div> */}
                          <div className="footer-list">
                            <h6>Service Links</h6>
                            <a target="_blank" href="/services"> app development </a>
                            <a target="_blank" href="/webdevelopment"> web development </a>
                            <a target="_blank" href="/payment">API Integration </a>
                            {
                              <a target="_blank" href="/websiteoptimization">
                                {" "}
                                website optimization{" "}
                              </a>
                            }
                            {/* <a href="/itsolution"> IT solution </a> */}
                            <a target="_blank" href="/qatesting"> QA testing </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="footer-sec-col">
                          {/* <div className="footer-country">
                            <p>Email</p>
                            <p>adarsh@osiyatech.com</p>
                          </div> */}
                          <div className="footer-list">
                            <h6>Newsletter</h6>
                            <input
                              type="text"
                              name="text"
                              placeholder="Enter Your Email*"
                              value={email_address}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <a target="_blank"
                              className="subscribe"
                              href="#"
                              onClick={getuserLocation}
                            >
                              {" "}
                              Subscribe
                            </a>
                          </div>
                          <div className="follow-us">
                            <h6>Follow Us On</h6>
                            <div className="social-icon d-flex">
                              <a target="_blank"
                                href="https://www.facebook.com/osiyatech/"
                                className="social-img m-1"
                              >
                                <img src={Image.facebook} />
                              </a>
                              <a target="_blank"
                                href="https://www.instagram.com/osiya_tech/"
                                className="social-img m-1"
                              >
                                <img src={Image.instagram} />
                              </a>
                              <a target="_blank"
                                href="https://twitter.com/osiyatech"
                                className="social-img m-1"
                              >
                                <img src={Image.twitter} />
                              </a>
                              <a target="_blank"
                                href="https://in.linkedin.com/company/osiyatech?original_referer=https%3A%2F%2Fwww.google.com%2F"
                                className="social-img m-1"
                              >
                                <img src={Image.linkedIn} />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-section">
          <p>© {new Date(date).getFullYear()} Osiyatech, All Rights Reserved.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;

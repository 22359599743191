import React, { useState } from "react";
import { NavDropdown } from "react-bootstrap";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import IconLibraryBooks from "@material-ui/icons/LibraryBooks";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    color: "blue",
    fontSize: "20px",
  },
  icon: {
    color: "white",
  },
}));

function DrawerComponent() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }
  return (
    <>
      <Drawer
        className="sidebar_menus"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List className="mobile-responsive main_mobile">
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/">Home</Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/portfolio">Portfolio</Link>
            </ListItemText>
          </ListItem>

          <ListItem className="mobile_dtroptoggle" button onClick={handleClick}>
            <ListItemText>
              <Link to="/All_Services">Services</Link>
            </ListItemText>
            {open ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <List component="div" disablePadding>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link to="/services">App Development</Link>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link to="/webdevelopment">Web Development</Link>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link to="/payment">API Integration</Link>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link to="/websiteoptimization">Website Optimization</Link>
                </ListItemText>
              </ListItem>
              {/* <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link to="/itsolution">IT Solution</Link>
                </ListItemText>
              </ListItem> */}
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link to="/qatesting">QA Testing</Link>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>
          {/* <ListItem> <NavDropdown className="MuiListItem-root MuiListItem-gutters" title="Services" id="nav-dropdown"> 
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
                 <Link to="/services">App Development</Link>
                 </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
                 <Link to="/webdevelopment">Web Development</Link>
                 </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            <Link to="/payment">Payment Gateway</Link>
                 </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            <Link to="/websiteoptimization">Website Optimization</Link>
                 </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            <Link to="/itsolution">IT Solution</Link>
                 </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            <Link to="/qatesting">QA Testing</Link>
                 </ListItemText>
          </ListItem>
                </NavDropdown></ListItem> */}
                          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/aboutus">About Us</Link>
            </ListItemText>
          </ListItem>

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/career">Career</Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/contactus">Contact Us</Link>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton
        className="toggle"
        onClick={() => setOpenDrawer(!openDrawer)}
      ></IconButton>
    </>
  );
}
export default DrawerComponent;

import React from "react";
import Image from "../assets/Image";
import {Container ,Dropdown,  DropdownButton} from 'react-bootstrap';  
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import DrawerComponent from "./Drawer";

const useStyles = makeStyles((theme) => ({
  // navitemlink: {
  //   marginLeft: theme.spacing(2),
  //   display: "flex",
  // },
  // // headerlogo: {
  // //   flexGrow: "1",
  // //   cursor: "pointer",
  // // },
  // navitem: {
  //   textDecoration: "none",
  //   color: "white",
  //   fontSize: "20px",
  //   marginLeft: theme.spacing(20),
  //   "&:hover": {
  //     color: "yellow",
  //   },
  // },
}));

function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppBar position="static">
      <CssBaseline />
      <Toolbar className="os_top_header">
        <Typography variant="h4" className="headerlogo">
          <a href="/">
            {" "}
            <img className="main-header" src={Image.logo} alt="" />
          </a>
          <a href="/">
            {" "}
            <img
              className="responsive-header"
              src={Image.responsiveHeader}
              alt=""
            />
          </a>
        </Typography>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="navbar-body">
              <div className="navbar-header">
                <div
                  className="collapse navbar-collapse justify-content-center"
                  id="navbarNav"
                >
                  <ul className="navitemlink">
                    <NavLink
                      activeclassname="navitem active"
                      to="/portfolio"
                      className="navitem"
                    >
                      Portfolio
                    </NavLink>

                    <li className="navitem dropdown dropdown-6">
                      <NavLink
                        to="/All_Services"
                        className="navitem service_item"
                      >
                        Services
                      </NavLink>
                      <ul className="dropdown_menu dropdown_menu--animated dropdown_menu-6">
                        <NavLink
                          activeclassname="navitem active"
                          to="/services"
                          className="navitem"
                        >
                          App Development
                        </NavLink>
                        <NavLink
                          activeclassname="navitem active"
                          to="/webdevelopment"
                          className="navitem"
                        >
                          Web Development
                        </NavLink>
                        <NavLink
                          activeclassname="navitem active"
                          to="/payment"
                          className="navitem"
                        >
                           API Integration
                        </NavLink>
                        {<NavLink
                          activeclassname="navitem active"
                          to="/websiteoptimization"
                          className="navitem"
                        >
                          Website Optimization
                        </NavLink> }
                        {/* <NavLink
                          activeclassname="navitem active"
                          to="/itsolution"
                          className="navitem"
                        >
                          IT Solution
                        </NavLink> */}
                        <NavLink
                          activeclassname="navitem active"
                          to="/qatesting"
                          className="navitem"
                        >
                          QA Testing
                        </NavLink>
                      </ul>
                    </li>

                    <NavLink
                      activeclassname="navitem active"
                      to="/aboutus"
                      className="navitem"
                    >
                      About Us
                    </NavLink>

                    <NavLink
                      activeclassname="navitem active"
                      to="/career"
                      className="navitem"
                    >
                      Career
                    </NavLink>
                    <NavLink
                      activeclassname="navitem active"
                      to="/contactus"
                      className="navitem"
                    >
                      Contact us
                    </NavLink>
                    {/* <NavLink
                     activeclassname="navitem active"
                      to="/get-a-quote"
                      className="get-a-quote"
                    >
                      Get A Quote
                    </NavLink> */}
                  </ul>
                </div>
              </div>

            </div>
          </nav>
        )}
      </Toolbar>
    </AppBar>
    
  );
}
export default Navbar;

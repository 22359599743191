import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import { GoogleApiWrapper } from "google-maps-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import FileUpload from "react-material-file-upload";

function ApplyDialogBox({ technology }) {
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email_address, setEmail_address] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [verified, setVerified] = useState(false);
  const [description, setDescription] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [mailError, setMailError] = useState("");
  const [Prompt, setIsSaved] = useState();
  const [showResults, setShowResults] = useState(false);

  const isNameValid = first_name !== "";
  const isLnameValid = last_name !== "";
  const isEmail = (email_address) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email_address);
  const isPhone = (phoneno) =>
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(phoneno);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function onChangeReCAPTCHA(value) {
    setVerified(true);
  }

  const handleFileUpload = async (event) => {
    setShowResults(true);
    const file = event[0];

    const fileType = file?.type;
    const fileSize = file?.size / 1024; // in MB
    console.log(fileSize);
    // Check if file type is docx or pdf
    if (
      fileType !==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
      fileType !== "application/pdf"
    ) {
      toast.error("Please upload a file of type docx or pdf.");
      return;
    }

    // Check if file size is between 3 MB to 10 MB
    // if (fileSize < 1 || fileSize > 10) {
    //   toast.error("Please upload a file between 3 MB to 10 MB.");
    //   return;
    // }

    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener("progress", (event) => {
      console.log(
        "upload : ",
        event.lengthComputable,
        event.loaded,
        event.total
      );
      if (event.lengthComputable) {
        const progressPercent = (event.loaded / event.total) * 100;
        setProgress(progressPercent);
      } else {
        setProgress("");
      }
    });
    try {
      xhr.open("POST", "/upload");
      await xhr.send(file);
    } catch (error) {}

    setTimeout(() => {
      setShowResults(false);
    }, 1000);

    setSelectedFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!isNameValid) {
      errors.first_name = "Name field is empty";
      setFirstNameError("First name is required");
    }
    if (!isLnameValid) {
      errors.last_name = "Last Name field is empty";
      setLastNameError("Last name is required");
    }
    if (description === "") {
      errors.description = "Last Name field is empty";
      setMessageError("Message is required");
    }

    if (email_address === "") {
      errors.email_address = "Email field is empty";
      setMailError("Email is required");
    } else {
      if (!isEmail(email_address)) {
        errors.email_address = "Wrong email";
        setEmail_address("");
      }
    }
    if (phoneno === "") {
      errors.phoneno = "Phone No is empty";
      setPhoneNoError("Phone No is required");
    } else {
      if (!isValidPhoneNumber(phoneno)) {
        errors.phoneno = "Numberrrrr is not valid";
        // setPhoneno("");
      }
    }

    try {
      const response = await axios.get(
        "https://geolocation-db.com/jsonp/a9e48c70-8b22-11ed-8d13-bd165d1291e3"
      );
      const data = response.data.replace(/^callback\(|\)$/g, "");

      const json = JSON.parse(data);
      console.log(json);
      let formData = new FormData();

      formData.append(
        "info",
        JSON.stringify({
          first_name: first_name,
          last_name: last_name,
          email_address: email_address,
          ip_address: json.IPv4,
          country: json.country_name,
          longitude: json.longitude,
          latitude: json.latitude,
          phoneno: phoneno,
          technology: technology,
          description: description,
        })
      );
      formData.append("file", selectedFile);

      try {
        const res = await axios.post(
          "https://osiyatech.com/apps/api/contact/career_form",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        console.log(res.status);
        if (res.status) {
          toast.success("Applied Success");
          setFirst_name("");
          setLast_name("");
          setEmail_address("");
          setPhoneno("");
          setDescription("");
        }

        handleClose();
      } catch (error) {
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error("Something Went Wrong");
        }
      }
    } catch (error) {
      if (error) toast.error(error);
      else {
        toast.error("Something Went Wrong");
      }
    }
  };

  return (
    <div>
      <Button variant="primary" size="lg" onClick={handleClickOpen}>
        Apply Now !
      </Button>{" "}
      <Dialog
        className="dialog-scroll"
        open={open}
        disableBackdropClick
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ paddingBottom: "0", textAlign: "center" }}
        >
          {"Apply Now"}
        </DialogTitle>
        <br />
        <DialogContent style={{ overflowX: "hidden" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 2, sm: 2, md: 2 }}
            style={{ paddingTop: "0" }}
          >
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                value={first_name}
                onChange={(e) => {
                  let value = e.target.value;
                  setFirst_name(e.target.value);
                  if (!value) {
                    setFirstNameError("First name is required");
                  } else {
                    setFirstNameError("");
                  }
                }}
              />
              {firstNameError && (
                <div style={{ color: "red" }}>{firstNameError}</div>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                value={last_name}
                onChange={(e) => {
                  let value = e.target.value;
                  setLast_name(e.target.value);
                  if (!value) {
                    setLastNameError("Last name is required");
                  } else {
                    setLastNameError("");
                  }
                }}
              />
              {lastNameError && (
                <div style={{ color: "red" }}>{lastNameError}</div>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email Address"
                variant="outlined"
                value={email_address}
                onChange={(e) => {
                  let value = e.target.value;
                  setEmail_address(e.target.value);
                  if (!value) {
                    setMailError("Email is required");
                  } else {
                    setMailError("");
                  }
                }}
              />
              {mailError && <div style={{ color: "red" }}>{mailError}</div>}
            </Grid>
            <Grid item xs={12} md={6}>
              <PhoneInput
                defaultCountry="IN"
                className={"input-phone-number "}
                placeholder="Enter phone number"
                value={phoneno}
                onChange={(e) => {
                  if (!isNaN(e)) {
                    setIsSaved();
                    try {
                      if (e.length > 0) {
                        if (!isValidPhoneNumber(e)) {
                          setPhoneNoError("Invalid Phone number");
                        } else {
                          setPhoneno(e);
                          setPhoneNoError("");
                        }
                      } else {
                        setPhoneno("");
                      }
                    } catch (error) {
                      setPhoneno("");
                    }
                  }
                }}
              />
              {phoneNoError && (
                <div style={{ color: "red" }}>{phoneNoError}</div>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                label="Technology"
                variant="outlined"
                value={technology}
                // onChange={(e) => setTechnology(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className="upload-btn">
              <FileUpload
                classes={{ root: "custom-file-upload" }}
                type="file"
                accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={(event) => {
                  handleFileUpload(event);
                  // onChangeHandler(event)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="desc-text"
                // style={{ width: 522 }}
                id="outlined-multiline-static"
                label="Description Message"
                multiline
                rows={4}
                inputProps={{ maxLength: 250 }}
                value={description}
                onChange={(e) => {
                  let value = e.target.value;
                  setDescription(e.target.value);
                  if (!value) {
                    setMessageError("Message is required");
                  } else {
                    setMessageError("");
                  }
                }}
              />
              {messageError && (
                <div style={{ color: "red" }}>{messageError}</div>
              )}
            </Grid>
            {showResults ? (
              <progress
                value={progress}
                max="100"
                style={{ padding: 31, width: "100%" }}
              >
                {progress}%
              </progress>
            ) : null}
          </Grid>
          <Grid item xs={6} style={{paddingTop: '8px'}}>
            <ReCAPTCHA
              sitekey="6LcBlJ0UAAAAAM4cy-aZdZYZNtYXGWlxVcpPxRGI"
              onChange={onChangeReCAPTCHA}
            />
          </Grid>
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus className="modal-apply">
            Close
          </Button>
          <Button
            onClick={handleSubmit}
            autoFocus
            className="modal-apply"
            disabled={!verified}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default ApplyDialogBox;

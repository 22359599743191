import React from "react";
import "../assets/css/style.css";
// import Slider from "react-slick";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Image from "../assets/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function Testimonialnew(props) {


  const testimonials = [
    {
      id: 1,
      name: "Josh Geppi",
      designation: "CEO",
      text: "Adarsh and his team have been a pleasure to work with. I highly recommend working with him. His understanding of what we wanted was great. His app development skill is incredible. I chose him over many candidates and I am very glad I did.",
      image: Image.testimonialFirst,
    },
    {
      id: 2,
      name: "Neville Blech",
      designation: "CEO",
      text: "I have used Adarsh and the Osiyatech team to get me out of a failing app developed by another developer. Not only have they done this with skill and technical ability but also have suggested and made time saving improvements for the future administration of the app.",
      image: Image.testimonialSec,
    },
    {
      id: 3,
      name: "David",
      designation: "CEO",
      text: "Highly recommended. Experienced and talented. I have recommended him to several of my business owner associated for all of their development needs. Very conscienscious and reasonably priced charges for their work I can unhesitatingly recommend them..",
      image: Image.testimonialFirst,
    },
    {
      id: 4,
      name: "Parath Chadha",
      designation: "CEO",
      text: "Adarsh has a dedication to his craft and has helped me in over 50 development projects over the years. We have developed a report and level of trust that we can depend upon. Website development, app development. Different e-commerce platforms, their team has the knowledge and expertise to build all projects of almost any size. Thank you.",
      image: Image.testimonialThird,
    },
    {
      id: 5,
      name: "Tina Carson",
      designation: "CEO",
      text: "Adarsh Tated is a dedicated professional with an excellent staff. Adarsh and his staff have worked on a few of my projects and I highly recommend him and his company. Adarsh will always stay in touch. Thank you for your expertise and excellent service Adarsh!",
      image: Image.testimonialFirst,
    },
    {
      id: 6,
      name: "Jack Yaakov Hillel",
      designation: "CEO",
      text: "Adarsh is very responsible and hard working man who creates amazing technological solutions. He and his team are doing amazing two apps for us. Provides amazing customer service 24/7. I'm pleased to work with him and his team, and wiling to do more projects.",
      image: Image.testimonialFirst,
    },
    {
      id: 7,
      name: "Neville Blech",
      designation: "CEO",
      text: "I have used Adarsh and the Osiyatech team to get me out of a failing app developed by another developer. Not only have they done this with skill and technical ability but also have suggested and made time saving improvements for the future administration of the app.",
      image: Image.testimonialSec,
    },
    {
      id: 8,
      name: "Mohammed Alebrahim",
      designation: "CEO",
      text: "Highly recommended. Experienced and talented. I have recommended him to several of my business owner associated for all of their development needs. Very conscienscious and reasonably priced charges for their work I can unhesitatingly recommend them.",
      image: Image.testimonialForth,
    },
  ];

  const settings = {
    // infinite: true,
    animateOut: "slideOutDown",
    animateIn: "zoomIn",
    smartSpeed: 20000,
    autoplay: 70000,
    mouseDrag: false,
    items: 1,
    dots: false,
  };

  return (
    <>
      <section className="testimonial-section">
        <div className="large-container">
          <div className="sec-title">
            <h2 className="all-title">What Our Clients Say</h2>
          </div>
          <OwlCarousel
            className="owlcarousel-slider"
            {...settings}
            margin={10}
            loop
            nav
            navText={[
              '<span className="arrow-leftt">←</span>',
              '<span className="arrow-right">→</span>',
            ]}
          >
            {testimonials.map((testimonial, index) => (
              <div className="testimonial-carousel"  key={testimonial.id}>
                <div className="testimonial-block" >
                  <div className="inner-box">
                    <div className="text">{testimonial.text}</div>
                    <div className="info-box">
                      <div className="thumb">
                        <img src={testimonial.image} alt="" />
                      </div>
                      <h4 className="name">{testimonial.name}</h4>
                      <span className="designation">
                        {testimonial.designation}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* <div>
              <span className="arrow-prev"></span>
              <span className="arrow-next"></span>
            </div> */}
          </OwlCarousel>
          <div className="thumb-layer paroller">
            <figure className="image">
              <img src={Image.userThumbs} alt="" />
            </figure>
          </div>
        </div>
      </section>


    


    </>
  );
}

export default Testimonialnew;

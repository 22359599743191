import logo from "./images/headerlogo.png";
import homeBanner from "./images/banner.png";
import responsiveHeader from "./images/headerlogo.png";
import footerLogo from "./images/footer-logo.png";
import heroImage from "./images/Web-devices.svg";
import herodeviceImage from "./images/Web-devices.png";
import aboutNewImage from "./images/about-us2.png";

// home-page-icons
import webDevelopment from "./images/Web_Development.png";
import appDevelopment from "./images/App_Development.png";
import websiteOptimization from "./images/Website_Optimization.png";
import itSolution from "./images/IT_Solution.png";
import paymentGateway from "./images/Payment_Gateway.png";
import qaTesting from "./images/QA_Testing.png";
import usaFlag from "./images/usa-flag.png";
import indiaFlag from "./images/india-flag.png";
import emailIcon from "./images/email-icon.png";
import serviceImage from "./images/services-img.png";
import optimizeweb from "./images/website-optimize.png";

// home-page-icons hover
import appDevelopmentHover from "./images/App_Development_Hover.png";
import webDevelopmentHover from "./images/Web_Development_Hover.png";
import itSolutionHover from "./images/IT_Solution_Hover.png";
import paymentGatewayHover from "./images/Payment_Gateway_Hover.png";
import websiteOptimizationHover from "./images/Website_Optimization_Hover.png";
import qaTestingHover from "./images/QA_Testing_Hover.png";
// home-page
import requirementsAnalysis from "./images/Requirements-Analysis.png";
import workingMove from "./images/Working-move-arrow.png";
import designWorking from "./images/Design.png";
import developmentWorking from "./images/Development.png";
import testingAnalysis from "./images/Testing.png";
import maintenanceAnalysis from "./images/Maintenance.png";
import deviceImg from "./images/device.png";

import Android from "./images/android.png";
import dotNet from "./images/Net.png";
import ajax from "./images/Ajax.png";
import angularJs from "./images/Angular-Js.png";
import AWS from "./images/AWS.png";
import Bootstrap from "./images/Bootstrap.png";
import CSS from "./images/CSS.png";
import iOS from "./images/iOS.png";
import Java from "./images/Java.png";
import JavaScript from "./images/JavaScript.png";
import Joomla from "./images/Joomla.png";
import Magneto from "./images/Magneto.png";
import mongoDB from "./images/Mongo-DB.png";
import nodeJS from "./images/Node-JS.png";
import PHP from "./images/PHP.png";
import Python from "./images/Python.png";
import reactJS from "./images/React-JS.png";
import Shopify from "./images/Shopify.png";
import vueJs from "./images/Vue-Js.png";
import Wordpress from "./images/Wordpress.png";

import testimonialFirst from "./images/Testimonials1.png";
import testimonialSec from "./images/Testimonials2.png";
import testimonialThird from "./images/Testimonials3.png";
import testimonialForth from "./images/Testimonials4.png";

import clientOne from "./images/Clients1.png";
import clientTwo from "./images/Clients2.png";
import clientThree from "./images/Clients3.png";
import clientFour from "./images/Clients4.png";
import clientFive from "./images/Clients5.png";
import clientSix from "./images/Clients6.png";
import clientSeven from "./images/Clients7.png";
import clientEight from "./images/Clients8.png";
import clientNine from "./images/Clients9.png";
import clientTen from "./images/Clients10.png";
import clientEleven from "./images/Clients11.png";
import clientTwelve from "./images/Clients12.png";

// social-icon
import facebook from "./images/fb.png";
import twitter from "./images/tweet.png";
import instagram from "./images/insta.png";
import linkedIn from "./images/linkedin.png";
import call from "./images/call.png";
import skype from "./images/skype.png";
import mail from "./images/mail.png";

// about us page start
import abousUsRight from "./images/about-us1.jpg";
import whyChooseUs from "./images/why-choose-us.png";
import creative from "./images/creative.jpg";
import fiveStar from "./images/five-star.jpg";
import fullyIntegrated from "./images/fully-integrated.jpg";
import vision from "./images/vision.png";
import aboutBanner from "./images/abutbanner-1.jpg";
import objectiveImage from "./images/object-c.png";

// contact us page start
import email from "./images/email.png";
import calling from "./images/calling.png";
import location from "./images/location.png";
//app dev. services page start
import appDevServices from "./images/appservice-right.png";
import serviceMaintainance from "./images/app_services1.png";
import serviceLaunching from "./images/app_services2.png";
import serviceTesting from "./images/app_services3.png";
import serviceDevelopment from "./images/app_services4.png";
import serviceDesigning from "./images/app_services5.png";
import serviceInfo from "./images/app_services6.png";

import ionic from "./images/ionic.png";
import reactNative from "./images/react-native.png";
import swift from "./images/swift.png";
import crossPlatform from "./images/cross_platform.png";

// achievement
import achImg_01 from "./images/achieve-img-01.png";
import achImg_02 from "./images/achieve-img-02.png";
import servicebanner from "./images/services_banner_section.png";
import My_Prople from "./images/my_prople.png";

//web dev. services page start
import webDevelopmentRight from "./images/web-development-right.png";
import webExpert from "./images/web-design-expert.png";

// career page start
import careerRight from "./images/career-main.png";
import careerTwo from "./images/career2.png";
import careerWhiteOne from "./images/career-white1.png";
import careerThree from "./images/career3.png";
import careerFour from "./images/career4.png";
import careerFive from "./images/career5.png";
import careerCheck from "./images/career-check.png";
import careerWhiteTwo from "./images/join2.png";
import careerWhiteThree from "./images/join3.png";
import careerWhiteFour from "./images/join4.png";
import careerWhiteFive from "./images/join5.png";
import careerOne from "./images/join6.png";
// payment Gateway start
import paymentRight from "./images/payment-right.png";
import paymentLeft from "./images/payment-left.png";
import NMI from "./images/NMI.png";
import merchant from "./images/merchant.png";
import paypal from "./images/paypal.png";
import magento from "./images/magento.png";
import stripe from "./images/stripe.png";
import paypalBox from "./images/paypal-box.png";
import magentoBox from "./images/magento-box.png";
import chainCommerce from "./images/chain-commerce.png";
import stripeBox from "./images/stripe-box.png";
import authorizeNet from "./images/authorize.net.png";
import globalPharm from "./images/global-pharm.png";
import merchantBox from "./images/merchant-box.png";
import nmiBox from "./images/nmi-box.png";
import inovio from "./images/inovio.png";
import brainTree from "./images/brain-tree.png";
import krack from "./images/krack.png";
import merchantEsolution from "./images/merchant-esolution.png";

// portfolio page start
import portfolioOne from "./images/portfolio1.jpg";
import portfolioTwo from "./images/portfolio2.jpg";
import portfolioThree from "./images/portfolio3.jpg";
import portfolioFour from "./images/portfolio4.jpg";
import portfolioFive from "./images/portfolio5.png";
import portfolioSix from "./images/portfolio6.png";
import portfolioSeven from "./images/portfolio7.png";
import portfolioEight from "./images/portfolio8.jpg";
import portfolioNine from "./images/portfolio9.jpg";
import doitsociety from "./images/doitsociety.png";
import ios2 from "./images/ios2.jpg";
import ios3 from "./images/ios3.jpg";
import spacetech from "./images/spacetech.jpg";
import lspsweb from "./images/lspsweb.png";
import webdevlop from "./images/web-devlop-6.jpg";
import webdevlop15 from "./images/web-devlop-15.jpg";
import flexico from "./images/flexico.jpg";
import webdevlop3 from "./images/web-devlop-3.jpg";
import webdevlop4 from "./images/web-devlop-4.jpg";
import webdevlop5 from "./images/web-devlop-5.jpg";
import portfoliob from "./images/portfolionew.jpg";

import tech1 from "./images/2.svg";
import tech2 from "./images/3.svg";
import tech3 from "./images/4.svg";
import tech4 from "./images/5.svg";
import bgtech from "./images/bgnew.png";
import angular from "./images/angularicon.png";
import awsi from "./images/awsicon.png";
import html5 from "./images/html5.png";
import css from "./images/css3.png";
import wordpress from "./images/wp.png";
import andriodicon from "./images/app.png";
import appleicon from "./images/apple.png";
import aboutImage from "./images/about.jpg";
import userThumbs from "./images/user-thumbs.png";
import contactImg from "./images/contact.aed789cf.png";
import PortfolioImgOne from "./images/portfolio_1.jpg";
import PortfolioImgTwo from "./images/portfolio_2.jpg";
import PortfolioImgthree from "./images/portfolio_3.jpg";
import PortfolioImgfour from "./images/portfolio_4.jpg";
import PortfolioImgfive from "./images/portfolio-5.jpg";
import PortfolioImgsix from "./images/portfolio_6.jpg";
import PortfolioImgsevan from "./images/portfolio_7.jpg";
import PortfolioImgeight from "./images/portfolio_8.jpg";
import PortfolioImgnine from "./images/portfolio-9.jpg";
// import careerbanner from "./images/careerbnr.png";

import contactbnr from "./images/contactbanner.jpg";
import servicebnr from "./images/servicebnr.jpg";

// service image

import img_001 from "./images/img-01.jpg";
import img_002 from "./images/img-02.jpg";
import img_003 from "./images/img-03.jpg";
import img_004 from "./images/img-04.jpg";
import img_005 from "./images/ic_launcher.png";

//portfolio inner images
import innerPortfolio1 from "./images/inner_img_1.jpg";
import innerPortfolio2 from "./images/inner_img_2.jpg";
import innerPortfolio3 from "./images/inner_img_3.jpg";
import innerPortfolio4 from "./images/inner_img_4.jpg";
import innerPortfolio5 from "./images/inner_img_5.jpg";
import innerPortfolio6 from "./images/inner_img_6.jpg";
import innerPortfolio7 from "./images/inner_img_7.jpg";
import innerPortfolio8 from "./images/inner_img_8.jpg";
import innerPortfolio9 from "./images/inner_img_9.jpg";
//URL imgs
import appleStore from "./images/apple-store.png";
import PlayStore from "./images/play-store.png";

//web-portfolio images
import webPortfolio_Image1 from "./images/web-portfolio-1.jpg";
import webPortfolio_Image2 from "./images/web-portfolio-2.jpg";
import webPortfolio_Image3 from "./images/web-portfolio-3.jpg";
import webPortfolio_Image4 from "./images/web-portfolio-4.jpg";
import webPortfolio_Image5 from "./images/web-portfolio-5.jpg";
import webPortfolio_Image6 from "./images/web-portfolio-6.jpg";
import webPortfolio_Image7 from "./images/web-portfolio-7.jpg";
import webPortfolio_Image8 from "./images/web-portfolio-8.jpg";
import webPortfolio_Image9 from "./images/web-portfolio-9.jpg";
import webPortfolio_Image10 from "./images/web-portfolio-10.jpg";
import portfoliolsps from "./images/portfoliolsps.jpg";
import webportfolio14 from "./images/webportfolio14.jpg";
import webportfolio15 from "./images/webportfolio15.jpg";
import webPortfolio_Image12 from "./images/web-portfolio-12.jpg";
import webPortfolio_Image13 from "./images/web-portfolio-15.jpg";
import webPortfolio_Image16 from "./images/web-portfolio-16.jpg";
import webPortfolio_Image18 from "./images/web-portfolio-18.jpg";
import webPortfolio_Image19 from "./images/web-portfolio-19.jpg";
import webPortfolio_Image20 from "./images/web-portfolio-20.jpg";
import webPortfolio_Image21 from "./images/web-portfolio-21.jpg";
import webPortfolio_Image22 from "./images/web-portfolio-22.jpg";
import webPortfolio_Image23 from "./images/web-portfolio-23.jpg";

 





import SharePointImage from "./images/Microsoft-SharePoint.png";
import microDynamics from "./images/microsoftdynamics.png";
import WhiteFriImage from "./images/white-env.png";
import FriEnvImage from "./images/frindly-env.png";
import ServicesBannerImg from "./images/services_banner_mobile.png";
import KlarnaImage from "./images/Klarna-Logo.wine.png";
import SpacespaceImage from "./images/squarespace.png";
import AliPay from "./images/alipay.png";
import WorldPay from "./images/world-pay.png";
import PaySafe from "./images/paysafe.png";
import AmazonPay from "./images/amazone-pay.png";
import GooglePay from "./images/google-pay.png";
import LocationImage from "./images/location_footer.png";

const Image = {
  ServicesBannerImg,
  servicebnr,
  img_001,
  img_002,
  img_003,
  img_004,
  img_005,
  appleStore,
  PlayStore,
  contactbnr,
  portfoliob,
  userThumbs,
  aboutBanner,
  SharePointImage,
  microDynamics,
  WhiteFriImage,
  FriEnvImage,
  KlarnaImage,
  SpacespaceImage,
  AliPay,
  WorldPay,
  PaySafe,
  AmazonPay,
  GooglePay,
  LocationImage,
  objectiveImage,
  // careerbanner,
  webPortfolio_Image1,
  webPortfolio_Image2,
  webPortfolio_Image3,
  webPortfolio_Image4,
  webPortfolio_Image5,
  webPortfolio_Image6,
  webPortfolio_Image7,
  webPortfolio_Image8,
  webPortfolio_Image9,
  webPortfolio_Image10,
  webPortfolio_Image12,
  webPortfolio_Image13, 
  webPortfolio_Image16, 
  webPortfolio_Image18, 
  webPortfolio_Image19, 
  webPortfolio_Image20, 
  webPortfolio_Image21, 
  webPortfolio_Image22, 
  webPortfolio_Image23, 

  
  webportfolio14,
  webportfolio15,
  portfoliolsps,
  innerPortfolio1,
  innerPortfolio2,
  innerPortfolio3,
  innerPortfolio4,
  innerPortfolio5,
  innerPortfolio6,
  innerPortfolio7,
  innerPortfolio8,
  innerPortfolio9,
  PortfolioImgnine,
  deviceImg,
  PortfolioImgOne,
  aboutImage,
  My_Prople,
  contactImg,
  PortfolioImgTwo,
  PortfolioImgthree,
  PortfolioImgfour,
  PortfolioImgfive,
  PortfolioImgsix,
  PortfolioImgsevan,
  PortfolioImgeight,
  servicebanner,
  achImg_01,
  achImg_02,
  html5,
  appleicon,
  andriodicon,
  wordpress,
  css,
  tech1,
  tech2,
  tech3,
  tech4,
  logo,
  serviceImage,
  angular,
  awsi,
  bgtech,
  homeBanner,
  responsiveHeader,
  footerLogo,
  aboutNewImage,
  herodeviceImage,
  indiaFlag,
  emailIcon,
  usaFlag,
  heroImage,
  Android,
  webDevelopment,
  webDevelopmentHover,
  appDevelopment,
  appDevelopmentHover,
  websiteOptimization,
  websiteOptimizationHover,
  itSolution,
  itSolutionHover,
  paymentGateway,
  paymentGatewayHover,
  qaTesting,
  qaTestingHover,
  requirementsAnalysis,
  workingMove,
  designWorking,
  developmentWorking,
  testingAnalysis,
  maintenanceAnalysis,
  dotNet,
  ajax,
  angularJs,
  AWS,
  Bootstrap,
  CSS,
  iOS,
  Java,
  JavaScript,
  Joomla,
  Magneto,
  mongoDB,
  nodeJS,
  PHP,
  Python,
  reactJS,
  Shopify,
  vueJs,
  Wordpress,
  testimonialFirst,
  testimonialSec,
  testimonialThird,
  testimonialForth,
  clientOne,
  clientTwo,
  clientThree,
  clientFour,
  clientFive,
  clientSix,
  clientSeven,
  clientEight,
  clientNine,
  clientTen,
  clientEleven,
  clientTwelve,
  facebook,
  twitter,
  linkedIn,
  instagram,
  call,
  mail,
  skype,
  abousUsRight,
  whyChooseUs,
  creative,
  vision,
  fullyIntegrated,
  fiveStar,
  email,
  calling,
  location,
  appDevServices,
  serviceMaintainance,
  serviceDesigning,
  serviceDevelopment,
  serviceLaunching,
  serviceTesting,
  serviceInfo,
  ionic,
  reactNative,
  swift,
  crossPlatform,
  webDevelopmentRight,
  webExpert,
  careerRight,
  careerTwo,
  careerWhiteOne,
  careerFive,
  careerFour,
  careerThree,
  careerCheck,
  paymentRight,
  paymentLeft,
  NMI,
  merchant,
  paypal,
  magento,
  stripe,
  paypalBox,
  magentoBox,
  chainCommerce,
  stripeBox,
  authorizeNet,
  globalPharm,
  merchantBox,
  nmiBox,
  inovio,
  brainTree,
  krack,
  merchantEsolution,
  portfolioOne,
  portfolioEight,
  portfolioFive,
  portfolioFour,
  portfolioNine,
  portfolioSeven,
  portfolioSix,
  portfolioThree,
  portfolioTwo,
  careerOne,
  careerWhiteTwo,
  careerWhiteThree,
  careerWhiteFour,
  careerWhiteFive,
  doitsociety,
  ios2,
  ios3,
  spacetech,
  lspsweb,
  webdevlop,
  webdevlop15,
  flexico,
  webdevlop3,
  webdevlop4,
  webdevlop5,
  optimizeweb,
};
export default Image;

import React, { useEffect, useState } from "react";
import "../../assets/css/style.css";
import Image from "../../assets/Image";
import { Accordion } from "react-bootstrap";
import { Button } from "react-bootstrap";
import ApplyHere from "./ApplyHere";
import WOW from "wowjs";
import ApplyDialogBox from "./ApplyDialogBox";
import { Helmet } from "react-helmet";

const Career = () => {
  const [selectedItem, setSelectedItem] = useState(0);

  // useEffect(() => {
  //   new WOW.WOW().init();
  // });

  return (
    <>
           <div>
      <Helmet>
        <title>Osiyatech-Careers</title>
        <meta name="Careers" content="This is the Careers page." />
 
      </Helmet>
 
    </div>
      <section className="about-us-section career-section d-flex careerbg">
        <div className="about-us-body">
          <div className="aboutus-text">
            <h1>Careers</h1>
            {/* <p>
              Home.<span>Careers</span>
            </p> */}
          </div>
        </div>
      </section>
      <section className="industrial-work start-you-journey">
        <div className="container">
          <div className="row ">
            <div className="col-lg-7 col-md-12">
              <div className="industrial-body">
                <h1 className="all-title">Start your journey with Osiyatech</h1>
                <h2 className="exciting-title">
                  Exciting opportunities for you
                </h2>
                {/* <div className="underline">
                  <p className="circle-line"></p>
                </div> */}
                <p className="text">
                  At Osiyatech we create opportunities for you to work with
                  varied clients from local to international and on small to
                  large-scale projects. We are looking for talented people who
                  can adapt to the rapid changes technology brings. You will
                  coordinate with the rest of the team which requires a
                  commitment to collaborative problem-solving and sophisticated
                  design. Joining Osiyatech will not only give you chance to
                  excel in your own field but in lateral disciplines as well.
                  We’ll pair you with a mentor to guide you and set you up for
                  future success. Moreover, you’ll also be part of a
                  culture-rich workplace, where every occasion is celebrated
                  joyfully.
                </p>
                <p className="text">
                  We're looking for someone who is comfortable with a
                  fast-moving environment and can learn quickly.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 d-flex align-items-center justify-content-center">
              <div className="about-us-right-img ">
                <img src={Image.careerRight} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us career-join-osiyatech">
        <div className="container">
          <div className="why-choose-us-title">
            <h2 className="all-title">Why should you join Osiyatech?</h2>

            {/* <div className="underline">
              <p className="circle-line"></p>
            </div> */}
          </div>
          <div className="career-icons">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-2 col-md-4 col-sm-12">
                <div className="career-icons-img">
                  <img className="non-hover" src={Image.careerOne} alt="" />
                  <img className="on-hover" src={Image.careerWhiteOne} alt="" />
                  <h6>Collaborative work</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12">
                <div className="career-icons-img">
                  <img className="non-hover" src={Image.careerTwo} alt="" />
                  <img className="on-hover" src={Image.careerWhiteTwo} alt="" />
                  <h6>Career growth</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12">
                <div className="career-icons-img">
                  <img className="non-hover" src={Image.careerThree} alt="" />
                  <img
                    className="on-hover"
                    src={Image.careerWhiteThree}
                    alt=""
                  />
                  <h6>Learning & development</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12">
                <div className="career-icons-img">
                  <img className="non-hover" src={Image.careerFour} alt="" />
                  <img
                    className="on-hover"
                    src={Image.careerWhiteFour}
                    alt=""
                  />
                  <h6>Friendly Environment </h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12">
                <div className="career-icons-img">
                  <img className="non-hover" src={Image.careerFive} alt="" />
                  <img
                    className="on-hover"
                    src={Image.careerWhiteFive}
                    alt=""
                  />
                  <h6>Work with global clients</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="working-process-section current-opening">
        <div className="container">
          <div className="working-process-body">
            <div className="title-body">
              <h2 className="all-title">Current Openings</h2>
              <p className="sub-heading">
                We have opening in almost all the technologies which is running
                in industry but mainly we are looking for enthusiastic young
                people who really want to perform on the given technologies.
              </p>
              {/* <div className="underline">
                <p className="circle-line"></p>
              </div> */}
            </div>
          </div>
          <Accordion flush>
            <Accordion.Item eventKey="0" onSelect={() => setSelectedItem(0)}>
              <Accordion.Header>React js developer</Accordion.Header>
              <Accordion.Body>
                <div className="what-require">
                  <div className="requirement d-flex justify-content-between">
                    <p>Experience</p>
                    <p>
                      Must have proven 0 to 2 years experience in a similar
                      profile
                    </p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Location</p>
                    <p>Indore</p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Job Type</p>
                    <p>Full Time</p>
                  </div>
                </div>
                <div className="other-roles">
                  <div className="what-responsiilities">
                    <h4>Responsibilities :</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        You will design, develop and deploy challenging
                        front-end of the product to build the future of business
                        process automation.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        You will be a prolific code contributor and will be
                        expected to demonstrate code and significant product
                        development contributor as it’s being built from
                        scratch.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Develop new or enhance existing user-facing features
                        using React.js and implementing them following
                        well-known React.js workflows (such as Flux or Redux)
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Building reusable components and front-end libraries for
                        future use
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Test, debug and optimize components for maximum
                        performance across a vast array of web-capable devices
                        and browsers
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Ensure that the components being developed and the
                        overall application is robust and easy to maintain
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Coordinate with the rest of the team working on
                        different layers of the infrastructure
                      </p>
                    </div>
                  </div>
                  <div className="what-responsiilities">
                    <h4>Skill Requirement:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Ability to visualize, design, and build wireframes </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Expertise in designing and creating scalable
                        high-performance Web applications.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Strong proficiency in JavaScript</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Thorough understanding of React.js and its core
                        principles
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Experience with popular React.js workflows (such as Flux
                        or Redux) .
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Experience with HTML5 & CSS3</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Experience with REST API's</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Excellent communication skills, both written and verbal
                      </p>
                    </div>
                  </div>

                  <div className="what-responsiilities">
                    <h4>Benefits:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Opportunity to develop your own team </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Fast growth and more visibility into the company.</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Experience to work on the latest technology</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Competitive Learning Environment with supportive
                        co-workers
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Employee friendly HR Policies</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Flexible working hours</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Liberal working atmosphere</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Occasional Parties</p>
                    </div>
                  </div>

                  <div className="submit-button">
                    <ApplyDialogBox className="apply-btn" technology="React" />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" onSelect={() => setSelectedItem(1)}>
              <Accordion.Header>Angular Developer</Accordion.Header>
              <Accordion.Body>
                <div className="what-require">
                  <div className="requirement d-flex justify-content-between">
                    <p>Experience</p>
                    <p>
                      Must have proven 0 to 2 years experience in a similar
                      profile
                    </p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Location</p>
                    <p>Indore</p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Job Type</p>
                    <p>Full Time</p>
                  </div>
                </div>
                <p>
                  We're looking for a qualified Angular Front-end Developer to
                  create and implement quality-control processes for our goods.
                  You will be responsible for assessing product characteristics,
                  defining test parameters, generating specific quality checks,
                  and putting up final test procedures for quality technicians
                  as a Front-end Developer
                </p>
                <div className="other-roles">
                  <div className="what-responsiilities">
                    <h4>Responsibilities :</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Should have experience of Angular 11 and MySQL</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Should have 1 year of experience in NodeJS and RESTFUL
                        API Development.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Strong knowledge of Front-End web development in Advance
                        JavaScript (ES6, ES7, ES8, ES9), OOPS Concept PLUS
                        exceptional debug skills
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Experience in Unit test development (Test Driven
                        Development – TDD)
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Experience building progressive responsive web
                        applications.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Experience in the concepts of code repositories
                        (Preference GIT) and be able to commit code
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>A team player with good communication skills</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Analytical thinking and problem-solving capability</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Good to have - Continuous Integration (CI) tools like
                        Jenkins.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Any experience on AWS will be a plus</p>
                    </div>
                  </div>
                  <div className="submit-button">
                    <ApplyDialogBox
                      className="apply-btn"
                      technology="Angular"
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" onSelect={() => setSelectedItem(2)}>
              <Accordion.Header>PHP Developer</Accordion.Header>
              <Accordion.Body>
                <div className="what-require">
                  <div className="requirement d-flex justify-content-between">
                    <p>Experience</p>
                    <p>
                      Must have proven 0 to 2 years experience in a similar
                      profile
                    </p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Location</p>
                    <p>Indore</p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Job Type</p>
                    <p>Full Time</p>
                  </div>
                </div>
                <p>
                  We are looking for an experienced software engineer with solid
                  experience in both PHP and on WordPress. We are looking for a
                  person who is open to new challenges, willing to learn and
                  mentor others, always looking to improve and above all someone
                  who takes pride in their work.
                </p>
                <div className="other-roles">
                  <div className="what-responsiilities">
                    <h4>Skill Requirement:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        PHP, WordPress, Themes, Plug-ins, WordPress Template
                        Design, API Integration.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>CSS, HTML, JQuery.</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p> Expertise with MySQL and DBMS Concept.</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Good understanding of cross- platform and cross- browser
                        issues.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        At least 1 years of relevant professional experience.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Experience in Development and Customization of WordPress
                        Websites and applications.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Strong development experience in PHP, JavaScript,
                        JQuery, HTML5 and CSS3.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Good Knowledge of WordPress and Woo - Commerce including
                        plugin development, Customization and Payment gateways
                        integrations.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Experience in developing Responsive websites.</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Good knowledge in handling content updates and
                        Integration of WordPress applications (Plugin, Hooks,
                        Themes, Filters etc..).
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Work Experience in Laravel and PHP Frameworks would be
                        added advantage.
                      </p>
                    </div>
                  </div>
                  <div className="what-responsiilities">
                    <h4>Responsibilities :</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Write clean, well-designed code and contribute in all
                        phases of the development lifecycle
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Ensuring high-performance availability, and managing all
                        technical aspects of the CMS.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Helping formulate an effective, responsive design and
                        turning it into a working theme and plugin.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        To understand CSS changes and their ramifications to
                        ensure consistent style across platforms and browsers
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Ability to convert comprehensive layout and wireframes
                        into working HTML pages
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Knowledge of how to interact with RESTful APIs and
                        formats (JSON, XML)
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Creation of plugins/widgets written to conform with the
                        WordPress coding standards.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Designing and implementing new features and
                        functionality
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Proficient understanding of code versioning tools such
                        as Git.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Software and databases to ensure strong optimization and
                        functionality
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        To create own themes based on requirements from HTML
                        scratch
                      </p>
                    </div>
                  </div>
                  <div className="submit-button">
                    <ApplyDialogBox className="apply-btn" technology="PHP" />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" onSelect={() => setSelectedItem(3)}>
              <Accordion.Header>Wordpress Developer</Accordion.Header>
              <Accordion.Body>
                <div className="what-require">
                  <div className="requirement d-flex justify-content-between">
                    <p>Experience</p>
                    <p>
                      Must have proven 0 to 2 years experience in a similar
                      profile
                    </p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Location</p>
                    <p>Indore</p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Job Type</p>
                    <p>Full Time</p>
                  </div>
                </div>
                <div className="other-roles">
                  <div className="what-responsiilities">
                    <h4>Roles and Responsibilities:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Must have a strong base in intermediate technologies
                        like PHP, WordPress, HTML, JS, CSS, Web Services
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Prior experience in e-commerce website development</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Understanding E-commerce business model and
                        functionality
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Specialized in all aspects of the e-commerce platform
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Custom app development as per requirements</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Willing to work with 6 days working organization</p>
                    </div>
                  </div>
                  <div className="what-responsiilities">
                    <h4>Skill Requirement:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Excellent Programming Skills</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Have good communication skills & professional behavior
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Candidate must be able to troubleshoot issues quickly
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Ability to handle multiple projects</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Should be responsible & professional towards work</p>
                    </div>
                  </div>
                  <div className="submit-button">
                    <ApplyDialogBox
                      className="apply-btn"
                      technology="Wordpress"
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" onSelect={() => setSelectedItem(4)}>
              <Accordion.Header>.Net Developer</Accordion.Header>
              <Accordion.Body>
                <div className="what-require">
                  <div className="requirement d-flex justify-content-between">
                    <p>Experience</p>
                    <p>
                      Must have proven 0 to 2 years experience in a similar
                      profile
                    </p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Location</p>
                    <p>Indore</p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Job Type</p>
                    <p>Full Time</p>
                  </div>
                </div>
                <div className="other-roles">
                  <div className="what-responsiilities">
                    <h4>Roles and Responsibilities:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Must have a strong base in intermediate technologies
                        like PHP, WordPress, HTML, JS, CSS, Web Services
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Prior experience in e-commerce website development</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Understanding E-commerce business model and
                        functionality
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Specialized in all aspects of the e-commerce platform
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Custom app development as per requirements</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Willing to work with 6 days working organization</p>
                    </div>
                  </div>
                  <div className="what-responsiilities">
                    <h4>Skill Requirement:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Excellent Programming Skills</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Have good communication skills & professional behavior
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Candidate must be able to troubleshoot issues quickly
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Ability to handle multiple projects</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Should be responsible & professional towards work</p>
                    </div>
                  </div>
                  <div className="submit-button">
                    <ApplyDialogBox className="apply-btn" technology=".Net" />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" onSelect={() => setSelectedItem(5)}>
              <Accordion.Header>SharePoint Developer</Accordion.Header>
              <Accordion.Body>
                <div className="what-require">
                  <div className="requirement d-flex justify-content-between">
                    <p>Experience</p>
                    <p> Must have proven 0 to 2 years experience in a similar
                      profile</p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Location</p>
                    <p>Indore</p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Job Type</p>
                    <p>Full Time</p>
                  </div>
                </div>
                <div className="other-roles">
                  <div className="what-responsiilities">
                    <h4>Roles and Responsibilities:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Must have a strong base in intermediate technologies
                        like PHP, WordPress, HTML, JS, CSS, Web Services
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Prior experience in e-commerce website development</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Understanding E-commerce business model and
                        functionality
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Specialized in all aspects of the e-commerce platform
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Custom app development as per requirements</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Willing to work with 6 days working organization</p>
                    </div>
                  </div>
                  <div className="what-responsiilities">
                    <h4>Skill Requirement:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Excellent Programming Skills</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Have good communication skills & professional behavior
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Candidate must be able to troubleshoot issues quickly
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Ability to handle multiple projects</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Should be responsible & professional towards work</p>
                    </div>
                  </div>
                  <div className="submit-button">
                    <ApplyDialogBox
                      className="apply-btn"
                      technology="SharePoint"
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6" onSelect={() => setSelectedItem(6)}>
              <Accordion.Header>Flutter Developer</Accordion.Header>
              <Accordion.Body>
                <div className="what-require">
                  <div className="requirement d-flex justify-content-between">
                    <p>Experience</p>
                    <p> Must have proven 0 to 2 years experience in a similar
                      profile</p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Location</p>
                    <p>Indore</p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Job Type</p>
                    <p>Full Time</p>
                  </div>
                </div>
                <div className="other-roles">
                  <div className="what-responsiilities">
                    <h4>Roles and Responsibilities:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Skill to Learning and implementing new technologies.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Basic knowledge in HTML, CSS and XML.</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        In depth understanding of Object Oriented programming
                        languages like Java and C++.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Experience with building responsive applications.</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Excellent problem-solving and communication skills.</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Ability to work independently as well as in a team
                        environment.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Familiarity using version control tools like Git. and
                        IDEs like Android Studio, VS Code etc.
                      </p>
                    </div>
                  </div>
                  <div className="what-responsiilities">
                    <h4>Skill Requirement:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Little knowledge in JavaScript, HTML, and CSS.</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Experience in development of Android/iOS applications
                        and have deployed them on Google Play/App Store.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>1+ years knowledge in mobile app development.</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Build reusable components and libraries for future use
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Knowledge of Dart programming language, Rest APIs and
                        integration of various APIs from third parties.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Write high-quality, clean, and maintainable code</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Understanding of the Agile Software Development Life
                        Cycle and been exposed to Scrum projects.
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Comfortable using test cases for debugging code,
                        enhancing performance and reliability.
                      </p>
                    </div>
                  </div>
                  <div className="submit-button">
                    {/* <Button variant="primary" size="lg">
                      Apply Now !
                    </Button>{" "} */}
                    <ApplyDialogBox className="apply-btn" technology="Flutter" />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7" onSelect={() => setSelectedItem(7)}>
              <Accordion.Header>React Native Developer</Accordion.Header>
              <Accordion.Body>
                <div className="what-require">
                  <div className="requirement d-flex justify-content-between">
                    <p>Experience</p>
                    <p> Must have proven 0 to 2 years experience in a similar
                      profile</p>
                    {/* <p>
                      We are seeking a highly skilled React Native developer to
                      join our team. As a React Native Developer, you will be
                      responsible for developing, testing, and deploying
                      high-quality mobile applications for iOS and Android
                      platforms.
                    </p> */}

                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Location</p>
                    <p>Indore</p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Job Type</p>
                    <p>Full Time</p>
                  </div>
                </div>
                <div className="other-roles">
                  <div className="what-responsiilities">
                    <h4>Roles and Responsibilities:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Develop and maintain high-quality mobile applications
                        using React Native
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Write clean and efficient code that follows industry
                        standards and best practices{" "}
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Collaborate with cross-functional teams to define,
                        design, and ship new features
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Conduct thorough testing to ensure high-quality software
                        that meets user requirements
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Participate in code reviews and provide constructive
                        feedback to other team members
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Stay up-to-date with new technologies and best practices
                        in mobile application development
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Troubleshoot and debug issues as they arise</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Work on bug fixes and improve application performance
                      </p>
                    </div>
                  </div>
                  <div className="what-responsiilities">
                    <h4>Skill Requirement:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Strong proficiency in JavaScript and ReactJS</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Experience in building mobile applications using React
                        Native
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Knowledge of iOS and Android platforms and their
                        differences
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Understanding of RESTful APIs and mobile application
                        architecture
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Familiarity with Git and Agile development methodologies
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Strong problem-solving skills and attention to detail
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Good communication and collaboration skills</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Bachelor's or Master's degree in Computer Science or
                        related field (preferred) If you are a React Native
                        developer with a passion for building high-quality
                        mobile applications, we encourage you to apply for this
                        exciting opportunity.
                      </p>
                    </div>
                  </div>
                  <div className="submit-button">
                    {/* <Button variant="primary" size="lg">
                      Apply Now !
                    </Button>{" "} */}
                    <ApplyDialogBox className="apply-btn" technology="React Native" />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8" onSelect={() => setSelectedItem(8)}>
              <Accordion.Header>Android Developer</Accordion.Header>
              <Accordion.Body>
                <div className="what-require">
                  <div className="requirement d-flex justify-content-between">
                    <p>Experience</p>
                    <p> Must have proven 0 to 2 years experience in a similar
                      profile</p>
                    {/* <p>
                      We are seeking a highly skilled React Native developer to
                      join our team. As a React Native Developer, you will be
                      responsible for developing, testing, and deploying
                      high-quality mobile applications for iOS and Android
                      platforms.
                    </p> */}

                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Location</p>
                    <p>Indore</p>
                  </div>
                  <div className="requirement d-flex justify-content-between">
                    <p>Job Type</p>
                    <p>Full Time</p>
                  </div>
                </div>
                <div className="other-roles">
                  <div className="what-responsiilities">
                    <h4>Roles and Responsibilities:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                      Design and build advanced applications for the Android platform
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                      Collaborate with cross-functional teams to define, design, and ship new features{" "}
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                      Work with outside data sources and APIs
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                      Unit-test code for robustness, including edge cases, usability, and general reliability
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                      Work on bug fixing and improving application performance
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                      Continuously discover, evaluate, and implement new technologies to maximize development efficiency
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Proven software development experience and Android skills development</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                      Proven working experience in Android app development
                      </p>
                    </div>
                  </div>
                  <div className="what-responsiilities">
                    <h4>Skill Requirement:</h4>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Have published at least one original Android app</p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                      Experience with Android SDK
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                      Experience working with remote data via REST and JSON
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                      Experience with third-party libraries and APIs
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Working knowledge of the general mobile landscape, architectures, trends, and emerging technologies Familiarity with Git and Agile development methodologies
                      </p>
                    </div>
                    <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                      Solid understanding of the full mobile development life cycle.
                      </p>
                    </div>
                    {/* <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>Good communication and collaboration skills</p>
                    </div> */}
                    {/* <div className="list-text d-flex align-items-start">
                      <img src={Image.careerCheck} alt="" />
                      <p>
                        Bachelor's or Master's degree in Computer Science or
                        related field (preferred) If you are a React Native
                        developer with a passion for building high-quality
                        mobile applications, we encourage you to apply for this
                        exciting opportunity.
                      </p>
                    </div> */}
                  </div>
                  <div className="submit-button">
                    {/* <Button variant="primary" size="lg">
                      Apply Now !
                    </Button>{" "} */}
                    <ApplyDialogBox className="apply-btn" technology="Android" />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
        </div>
      </section>
      {/* <section className="apply-form">
        <div className="container">
          <ApplyHere />
        </div>
      </section> */}
    </>
  );
};

export default Career;

import React, { useEffect } from "react";
import "../../assets/css/style.css";
import Image from "../../assets/Image";
import StarOutlinedIcon from "@material-ui/icons/StarOutlined";
import WOW from "wowjs";
import AutoPlay from "./carousel";
import Testimonialnew from "../testimonialnew";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  useEffect(() => {
    new WOW.WOW().init();
  });

  
  return (
    <>
           <div>
      <Helmet>
        <title>Osiyatech-About Us</title>
        <meta name="Contact Us" content="This is the Contact Us page." />
 
      </Helmet>
 
    </div>
      <div className="about-main">
        <section className="about-us-section d-flex aboutusbg">
          <div className="about-us-body">
            <div className="aboutus-text">
              <h1>About Us</h1>
              {/* <p>Home.<span>About</span></p> */}
            </div>
          </div>
        </section>
        <section className="industrial-work">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="industrial-body">
                  {/* <h6 className="title-clr">Welcome to osiyatech</h6> */}
                  <h2 className="all-title">
                    The best reliable solution for industrial works
                  </h2>

                  <p className="text">
                  Osiyatech is one of central India's most trusted IT service providers! Since our inception in 2012, we have expanded in size and scale and a devoted customer base. Osiyatech provides best-in-class services for all types of software, website and mobile app development, web design, API integration, out-sourcing, website maintenance, and custom solutions based on user requirements. We take you through a detailed requirement analysis and offer the most suitable solutions with the help of our excellent team of business analysts. Our experts work in multitude technologies from Android, iOS, Web 2.0, ASP.Net, PHP development, React, Flutter, Node, and other solid technologies that open up new avenues for growth and business.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 d-flex align-items-center justify-content-center">
                <div className="about-us-right-img right-about-img">
                  <img src={Image.aboutNewImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="why-choose-us">
          <div className="container">
            <div className="why-choose-us-title ">
              <h2 className="all-title">Why Choose Us</h2>

              {/* <div className="underline">
                <p className="circle-line"></p>
              </div> */}
            </div>
            <div className="why-choose-us-body">
              <div className="row">
                <div className="col-lg-5 col-md-12 d-flex align-items-center justify-content-center">
                  <div className="why-choose-us-left ">
                    <img src={Image.whyChooseUs} alt="" />
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="why-we-choose-right ">
                    <div className="icons-body d-flex align-items-center ">
                      <div className="icon-img">
                        <img src={Image.creative} alt="" />
                      </div>
                      <div className="icon-text">
                        <h5>Creative outlook</h5>
                        <p>
                        We'll provide you with fresh, innovative ideas to bring your project to life.
                        </p>
                      </div>
                    </div>
                    <div className="icons-body right-center d-flex align-items-center p-2 ">
                      <div className="icon-img">
                        <img src={Image.fullyIntegrated} alt="" />
                      </div>
                      <div className="icon-text">
                        <h5>Fully integrated service</h5>
                        <p>
                        As a leading digital agency, we can offer fully integrated services beyond website design.
                        </p>
                      </div>
                    </div>
                    <div className="icons-body d-flex align-items-center ">
                      <div className="icon-img">
                        <img src={Image.fiveStar} alt="" />
                      </div>
                      <div className="icon-text">
                        <h5>Five-star support team</h5>
                        <p>
                        Whether it's during development or after deployment, we always provide five-star assistance so you can feel at ease about your project.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="vision-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12  wow animate__animated animate__zoomIn animate__slower ">
                <div className="vision-col">
                  <div className="vision-box">
                    <h5>Vision</h5>
                    <p>
                      This slide is 100% editable. Adapt it to your needs and
                      capture your audience's attention.
                    </p>
                  </div>
                  <div className="vision-icon">
                    <img src={Image.vision} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12  wow animate__animated animate__zoomIn animate__slower ">
                <div className="vision-col">
                  <div className="vision-box">
                    <h5>Goal</h5>
                    <p>
                      This slide is 100% editable. Adapt it to your needs and
                      capture your audience's attention.
                    </p>
                  </div>
                  <div className="vision-icon">
                    <img src={Image.vision} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <Testimonialnew from={'aboutus'}></Testimonialnew>
        {/* <section className="testimonial-section">
          <div className="container">
            <div className="testimonial-body">
              <div className="title-body wow animate__animated animate__fadeInUp animate__slower">
                <h6 className="title-clr">Testimonials</h6>
                <h2 className="all-title">What our client's say</h2>
                <p className="sub-heading">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                  facilis vel consequatur tempora atque blanditiis
                  exercitationem incidunt, alias corporis quam assumenda dicta.
                </p> */}
        {/* <div className="underline">
                  <p className="circle-line"></p>
                </div> */}
        {/* </div>
              <section>
                <AutoPlay />
              </section>
            </div>
          </div>
        </section> */}
      </div>
    </>
  );
};

export default AboutUs;

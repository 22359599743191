import React, { useEffect } from "react";
import "../../assets/css/style.css";
import Image from "../../assets/Image";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import StarOutlinedIcon from "@material-ui/icons/StarOutlined";
import WOW from "wowjs";

const QATesting = () => {
  return (
    <>
      <section className="appservices-section d-flex">
        <div className="appservices-body">
          <div className="appservices-text">
            <h1>Services</h1>
            {/* <p>Home . Services<span> . QA Testing</span></p> */}
            <p>
              <span>QA Testing</span>
            </p>
          </div>
        </div>
      </section>

      <section className="what-we-provide">
        <div className="container">
          <div className="title-body wow ">
            <h6 className="title-clr">What We Provide</h6>
            <h2 className="all-title">
            Delivering products that create value-oriented businesses
            </h2>
            <p className="sub-heading">
            We place a high value on customer satisfaction and high-quality products. In order to better serve end users, we endeavor to provide clients with solutions that keep them up to speed with current technology.

            </p>
            {/* <div className='underline'>
              <p className='circle-line'></p>
              </div> */}
          </div>
          <div className="what-we-provide-body">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12  ">
                <div className="what-we-provide-col">
                  <div className="icon-img">
                    <img className="non-hover" src={Image.webDevelopment} />
                    <img
                      className="hovericons"
                      src={Image.webDevelopmentHover}
                    />
                  </div>
                  <h5> Web Application Testing</h5>
                  <p>
                  Our web development services range from e-commerce to enterprise-level systems, all subjected to rigorous testing techniques to ensure quality.

                  </p>
                  {/* <a className="read-more" href="#">
                    <ArrowRightAltIcon />
                  </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ">
                <div className="what-we-provide-col">
                  <div className="icon-img">
                    <img className="non-hover" src={Image.appDevelopment} />
                    <img
                      className="hovericons"
                      src={Image.appDevelopmentHover}
                    />
                  </div>
                  <h5>Mobile Application Testing</h5>
                  <p>
                  Our Testing Services combined with a variety of testing tools, mobile platforms, and testing experts offer the opportunity to maintain high quality at reduced costs.

                  </p>
                  {/* <a className="read-more" href="#">
                    <ArrowRightAltIcon />
                  </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ">
                <div className="what-we-provide-col">
                  <div className="icon-img">
                    <img className="non-hover" src={Image.paymentGateway} />
                    <img
                      className="hovericons"
                      src={Image.paymentGatewayHover}
                    />
                  </div>
                  <h5> Performance Testing</h5>
                  <p>
                  We monitor logs, examine behavior and apply appropriate test cases to performance factors.

                  </p>
                  {/* <a className="read-more" href="#">
                    <ArrowRightAltIcon />
                  </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ">
                <div className="what-we-provide-col">
                  <div className="icon-img">
                    <img
                      className="non-hover"
                      src={Image.websiteOptimization}
                    />
                    <img
                      className="hovericons"
                      src={Image.websiteOptimizationHover}
                    />
                  </div>
                  <h5> Security Testing</h5>
                  <p>
                  We tirelessly run through rigorous test cases to identify possible loopholes and avoid vulnerabilities to ensure your system is safe.

                  </p>
                  {/* <a className="read-more" href="#">
                    <ArrowRightAltIcon />
                  </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ">
                <div className="what-we-provide-col">
                  <div className="icon-img">
                    <img className="non-hover" src={Image.itSolution} />
                    <img className="hovericons" src={Image.itSolutionHover} />
                  </div>
                  <h5>Automation Testing</h5>
                  <p>
                  Apart from discovering bugs with tools, we strive to develop software that adheres to excellent coding standards, as well as optimize it.

                  </p>
                  {/* <a className="read-more" href="#">
                    <ArrowRightAltIcon />
                  </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ">
                <div className="what-we-provide-col">
                  <div className="icon-img">
                    <img className="non-hover" src={Image.qaTesting} />
                    <img className="hovericons" src={Image.qaTestingHover} />
                  </div>
                  <h5>Cloud Testing</h5>
                  <p>
                  Osiyatech offers futuristic testing for testing cloud resources, such as architecture or software offerings. It also offers cloud tools as part of a quality assurance strategy.
       </p>
                  {/* <a className="read-more" href="#">
                    <ArrowRightAltIcon />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QATesting;

import { useState, useEffect } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "../../assets/css/style.css";
import Image from "../../assets/Image";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  Col,
  Row,
} from "react-bootstrap";
import { GoogleApiWrapper } from "google-maps-react";
import { MapContainer } from "../Googlemap";
import axios from "axios";
import { Helmet } from "react-helmet";

const ContactUs = (props) => {
  const mapStyles = {
    width: "100%",
    height: "100%",
  };
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email_address, setEmailAddress] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [ip_address, setIp_address] = useState("127.0.0.1");
  const [country, setCountry] = useState("IN");
  const [longitude, setLongitude] = useState("987");
  const [latitude, setLatitude] = useState("45");
  const [description, setDescription] = useState("");
  const [details, setDetails] = useState(null);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [mailError, setMailError] = useState("");
  const [verified, setVerified] = useState(false);
  const [Prompt, setIsSaved] = useState();
  const isNameValid = first_name !== "";
  const LisNameValid = last_name !== "";
  const isEmail = (email_address) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email_address);
  const isPhone = (phoneno) =>
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(phoneno);

  const getuserLocation = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.get(
        "https://geolocation-db.com/jsonp/a9e48c70-8b22-11ed-8d13-bd165d1291e3"
      );
      let location = response.data.replace("callback(", "").replace(")", "");
      // let location = response
      let location1 = JSON.parse(location);
      console.log("location details: ", location1);
      setLatitude(location1.latitude);
      setLongitude(location1.longitude);
      setCountry(location1.country_name);
      setIp_address(location1.IPv4);
      handleSubmit(location1);
    } catch (error) {
      console.log("allow location permission", e);
      // alert("allow location permission");
    }
  };

  function onChangeReCAPTCHA(value) {
    setVerified(true);
    console.log("@@@@@@captcha",value)
  }

  const handleSubmit = async (e) => {
    // const value = e.target.value;
    const errors = {};
    if (!isNameValid) {
      errors.first_name = "Name field is empty";
      setFirstNameError("First name is required");
    } else {
      if (!isNameValid) setFirstNameError("Name is not valid");
    }
    if (!LisNameValid) {
      errors.last_name = "Last Name field is empty";
      setLastNameError("Last name is required");
    }
    if (description === "") {
      errors.description = "Last Name field is empty";
      setMessageError("Message is required");
    }

    if (email_address === "") {
      errors.email_address = "Email field is empty";
      setMailError("Email is required");
    } else {
      if (!isEmail(email_address)) {
        errors.email_address = "Wrong email";
        setEmailAddress("");
      }
    }
    if (phoneno === "") {
      errors.phoneno = "Phone No is empty";
      setPhoneNoError("Phone No is required");
    } else {
      if (!isValidPhoneNumber(phoneno)) {
        errors.phoneno = "Numberrrrr is not valid";
        // setPhoneno("");
      }
    }
    var len = Object.keys(errors).length;
    if (len === 0) {
      let item = {
        first_name,
        last_name,
        email_address,
        phoneno,
        ip_address,
        country,
        longitude,
        latitude,
        description,
      };
      try {
        const res = await axios.post(
          "https://osiyatech.com/apps/api/contact/contact_form",
          item
        );
        console.log(res.status);
        toast.success("Applied Success");
        setFirstName("");
        setLastName("");
        setEmailAddress("");
        setPhoneno("");
        setDescription("");
        console.log(res);
      } catch (e) {
        console.log(e);
        toast.error("Something Went Wrong");
      }
    }
  };

  

  return (
    <>
           <div>
      <Helmet>
        <title>Osiyatech-Contact Us</title>
        <meta name="Contact Us" content="This is the Contact Us page." />
 
      </Helmet>
 
    </div>
      <section className="contactus-section d-flex">
        <div className="contactus-body">
          <div className="contactus-text">
            <h1>Contact Us</h1>
            {/* <p>Home.<span>Contact Us</span></p> */}
          </div>
        </div>
      </section>
      <section className="location-card-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center contact-box ">
              <div className="contact-box-body">
                <img src={Image.email} />
                <h5>Email</h5>
                <p>
                  <a href="mailto:hr@osiyatech.com">career@osiyatech.com</a>
                </p>
                <p>
                  <a href="mailto:adarsh@osiyatech.com">
                    enquire@osiyatech.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center contact-box">
              <div className="contact-box-body">
                <img src={Image.location} />
                <h5>Location</h5>
                <p>
                  21, Chain singh ka bagicha, New palasia, Near Om Namkeen,
                  Indore (M.P.)
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center contact-box">
              <div className="contact-box-body">
                <img src={Image.calling} />
                <h5>Call Us</h5>
                <p>
                  <a href="tel:0731-499-9969">0731-499-9969</a>{" "}
                </p>
                <p>
                  <a href="tel:+1.949-544-1199">+1.949-544-1199</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="deneb_contact contact_v1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <div className="contact_section_title">
                <h4> contact info </h4>
              </div> */}
            </div>
          </div>
        </div>
        <section className="contact_form_section">
          <form>
            <div className="container">
              <div className="row align-items-center">
                <div className="contact_section_title">
                  <h4> contact info </h4>
                </div>
                <div className="col-lg-6">
                  <div className="deneb_img_box text-center">
                    <img
                      src={Image.contactImg}
                      className="img-fluid"
                      alt="contact"
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form_section_wrapper">
                    <div className="section_title">
                      <h2>Get in touch with us</h2>
                      {/* <p>
                      When unknow printer took a gallery of type and scramblted
                      it to make a type specimen book
                    </p> */}
                    </div>
                    <div className="contact_form">
                      <form action="#">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form_group">
                              <input
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setFirstName(e.target.value);
                                  if (!value) {
                                    setFirstNameError("First name is required");
                                  } else {
                                    setFirstNameError("");
                                  }
                                }}
                                name="first_name"
                                value={first_name}
                                type="text"
                                className="form_control"
                                placeholder="First Name"
                                // required=""
                                // value=""
                              />
                              {firstNameError && (
                                <div style={{ color: "red" }}>
                                  {firstNameError}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form_group">
                              <input
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setLastName(e.target.value);
                                  if (!value) {
                                    setLastNameError("Last name is required");
                                  } else {
                                    setLastNameError("");
                                  }
                                }}
                                name="last_name"
                                value={last_name}
                                type="text"
                                className="form_control"
                                placeholder="Last Name"
                                // required=""
                                // value=""
                              />
                              {lastNameError && (
                                <div style={{ color: "red" }}>
                                  {lastNameError}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form_group">
                              <input
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setEmailAddress(e.target.value);
                                  if (!value) {
                                    setMailError("Email is required");
                                  } else {
                                    setMailError("");
                                  }
                                }}
                                name="email_address"
                                value={email_address}
                                type="text"
                                className="form_control"
                                placeholder="Email Address"
                                // required=""
                                // value=""
                              />
                              {mailError && (
                                <div style={{ color: "red" }}>{mailError}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form_group">
                              <PhoneInput
                                defaultCountry="IN"
                                className={"input-phone-number "}
                                placeholder="Enter phone number"
                                value={phoneno}
                                // onChange={setPhoneno}
                                // onBlur={() => {
                                //   if (!isValidPhoneNumber(phoneno)) {
                                //     setPhoneNoError("Invalid Phone number");

                                //   } else {
                                //     setPhoneNoError("");
                                //   }
                                // }}
                                onChange={(e) => {
                                  // setPhoneno(e);
                                  if (!isNaN(e)) {
                                    setIsSaved();
                                    try {
                                      if (e.length > 0) {
                                        if (!isValidPhoneNumber(e)) {
                                          setPhoneNoError(
                                            "Invalid Phone number"
                                          );
                                        } else {
                                          setPhoneno(e);
                                          setPhoneNoError("");
                                        }
                                      } else {
                                        setPhoneno("");
                                      }
                                    } catch (error) {
                                      setPhoneno("");
                                    }
                                  }
                                }}
                              />
                              {phoneNoError && (
                                <div style={{ color: "red" }}>
                                  {phoneNoError}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form_group">
                              <textarea
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setDescription(e.target.value);
                                  if (!value) {
                                    setMessageError("Message is required");
                                  } else {
                                    setMessageError("");
                                  }
                                }}
                                name="user_message"
                                value={description}
                                className="form_control"
                                placeholder="Message"
                                // required=""
                              ></textarea>
                              {messageError && (
                                <div style={{ color: "red" }}>
                                  {messageError}
                                </div>
                              )}
                            </div>
                          </div>

                          <ReCAPTCHA
                            sitekey="6LcBlJ0UAAAAAM4cy-aZdZYZNtYXGWlxVcpPxRGI"
                            onChange={onChangeReCAPTCHA}
                          />

                          <div className="col-lg-12">
                            <div className="button_box">
                              <button
                                //  disabled={!verified}
                                onClick={getuserLocation}
                                className="deneb_btn"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </section>

      <section className="map-container">{/* <MapContainer/> */}</section>
    </>
  );
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyCo2237ZA88fYPi8OBVxr1VfoQjx2WBrLM",
})(ContactUs);

import React, { useEffect } from "react";
import "../../assets/css/style.css";
import Image from "../../assets/Image";
import WOW from "wowjs";

const ItSolution = () => {
  useEffect(() => {
    new WOW.WOW().init();
  });
  return (
    <>
      <section className="appservices-section d-flex">
        <div className="appservices-body">
          <div className="appservices-text">
            <h1>Services</h1>
            {/* <p>Home . Services<span> . IT Solution</span></p> */}
            <p>
              <span>IT Solution</span>
            </p>
          </div>
        </div>
      </section>
      <section className="industrial-work web-development-service">
        <div className="container">
          <div className="row border-bottom">
            <div className="col-lg-7 col-md-12">
              <div className="industrial-body">
                {/* <h6 className="title-clr">Welcome to osiyatech</h6> */}
                <h2 className="all-title">
                  Our team of experts drives technology to your benefit.
                </h2>
                {/* <div className='underline'>
              <p className='circle-line'></p>
              </div> */}
                <p className="text pt-4">
                  Osiyatech is, at the outset, concerned with solving business
                  challenges by employing technologies that are shaping the
                  world. As technology engulfs all industries there are, it
                  becomes increasingly important to respond to the need of the
                  hour, which is, being active at the Tech-fore.
                </p>
                <p className="text">
                  If your business has been posed by a new challenge or you want
                  to improve your efficiency of operations, in terms of time and
                  cost, we are at your disposal.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 d-flex align-items-center justify-content-center">
              <div className="about-us-right-img">
                <img src={Image.webDevelopmentRight} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us web-development-process">
        <div className="container">
          <div className="why-choose-us-title ">
            <h2 className="all-title">IT solution services we offer include</h2>
            {/* <p className="sub-heading">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse.
            </p> */}
            {/* <div className='underline'>
              <p className='circle-line'></p>
            </div> */}
          </div>
          <div className="services-icons">
            <div className="row">
              <div className="col-md-6 p-2">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.serviceInfo} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>IT Assessments</h5>
                    <p>
                      Our information technology assessments help you gain a
                      better understanding of your IT environment to support
                      your key business goals and objectives.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-2">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.serviceDesigning} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>IT Strategy & Planning</h5>
                    <p>
                      Our dedicated team of technical experts work with you to
                      develop an IT strategy to ensure your technology decisions
                      meet your business needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-2">
                <div className="icons-col d-flex  ">
                  <div className="icon-box">
                    <img src={Image.serviceDevelopment} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Business Continuity & Disaster Recovery</h5>
                    <p>
                      We help you devise a business continuity plan to keep your
                      critical systems and processes safe while providing you
                      with peace of mind.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-2">
                <div className="icons-col d-flex  ">
                  <div className="icon-box">
                    <img src={Image.serviceTesting} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Governance, Risk, & Compliance</h5>
                    <p>
                      We work with you to assess, plan, implement, and manage IT
                      frameworks for a successful alignment of infrastructure
                      and organizational strategy.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-2">
                <div className="icons-col d-flex  ">
                  <div className="icon-box">
                    <img src={Image.serviceLaunching} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Go live</h5>
                    <p>
                      Once we finished testing and ensure that the product is
                      stable, usable, secure and reliable, we are ready for the
                      launch.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-2">
                <div className="icons-col d-flex  ">
                  <div className="icon-box">
                    <img src={Image.serviceMaintainance} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Maintenance</h5>
                    <p>
                      There is always room for improvement as we all work in
                      limitless progress. That`s why we stay in touch with a
                      client even after products’ release and ready to solve any
                      issue at any time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why-choose-us trending-technology itsolution ">
        <div className="container">
          <div className="why-choose-us-title">
            <h2 className="all-title">
              How we ensure the resolution of your problem.
            </h2>
            {/* <p className="sub-heading">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse.
            </p> */}
            {/* <div className='underline'>
              <p className='circle-line'></p>
            </div> */}
          </div>
          <div className="services-icons ">
            <div className="row">
              <div className="col-lg-12">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.serviceMaintainance} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>
                      Understanding Business Needs And Developing An IT Strategy
                    </h5>
                    <p>
                      We, as our first and foremost responsibility, undertake
                      the task of comprehensively analyzing and understanding
                      your business in-and-out, the specific challenge that you
                      faced in your business or the business need that prompted
                      you for a solution. We do not take this step lightly as
                      our grasping of your operations and workings only allows
                      us to pick out the best IT strategy for the solution for
                      your business.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.serviceMaintainance} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Discussing The Possible Solutions</h5>
                    <p>
                      We chalk out the various possible solutions for you,
                      explain you the complexities of each, and engage in
                      discussions with you to solve all your queries. If all you
                      have is an idea in mind, we will take you ahead from there
                      and you can trust us with your most complex needs. We are
                      a business solution provider company who will first
                      understand your business and then craft the perfect
                      solution, rather than jumping on to the task at once.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="icons-col d-flex ">
                  <div className="icon-box">
                    <img src={Image.serviceMaintainance} alt="" />
                  </div>
                  <div className="icons-text">
                    <h5>Arriving At The Perfect Plan</h5>
                    <p>
                      After extensive research and consideration, we arrive with
                      you at the most suitable IT strategy for your business
                      that lies within your time and cost constraints.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ItSolution;

import { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import ScrollTop from "./Components/pages/Scrolltop";
import Navbar from "./Components/Navbar";
import Home from "./Components/pages/Home";
import AboutUs from "./Components/pages/AboutUs";
import Services from "./Components/pages/Services";
import Portfolio from "./Components/pages/Portfolio";
import Career from "./Components/pages/Career";
import ContactUs from "./Components/pages/ContactUs";
import Footer from "./Components/pages/footer";
import WebDevelopment from "./Components/pages/WebDevelopment";
import PaymentGateway from "./Components/pages/PaymentGateway";
import WebsiteOptimization from "./Components/pages/WebsiteOptimization";
import ItSolution from "./Components/pages/ItSolution";
import QATesting from "./Components/pages/QATesting";
import All_Services from "./Components/pages/All_Services";
// import Popup from "./Components/pages/CookieConsent"
import { initGA,logPageView  } from './Components/GoogleAnalytics';
import TawkToScript from './Components/pages/TawkToScript';
import FlotingWhatsupp from './Components/pages/FlotingWhatsupp';
import './App.css'
function App() {
  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  return (
    <>
      <Navbar />
      <ScrollTop>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/All_Services" element={<All_Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/webdevelopment" element={<WebDevelopment />} />
          <Route path="/payment" element={<PaymentGateway />} />
          <Route path="/websiteoptimization" element={<WebsiteOptimization />} />
          <Route path="/itsolution" element={<ItSolution />} />
          <Route path="/qatesting" element={<QATesting />} />
          <Route path="*"element={<div style={{ textAlign: "center", padding: 50 }}>
                <h2>404 Not found</h2>
              </div>
            }
          />
        </Routes>
        {/* <Popup/> */}
      </ScrollTop>
      <Footer />

      <div>
      {/* Other components */}
      <TawkToScript />
      <FlotingWhatsupp />
    </div>

    </>
  );
}
export default App;

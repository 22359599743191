import React, { useEffect } from "react";
import "../../assets/css/style.css";
import Image from "../../assets/Image";
import WOW from "wowjs";

const PaymentGateway = () => {
  useEffect(() => {
    new WOW.WOW().init();
  });
  return (
    <>
     <section className="appservices-section d-flex payment_services">
        <div className="about-us-body">
          <div className="aboutus-text">
            <h1>Services</h1>
            {/* <p>Home . Services<span> . Payment Gateway</span></p> */}
            <p>
              <span>Payment Gateway</span>
            </p>
          </div>
        </div>
      </section>
      <section className="industrial-work">
        <div className="container">
          <div className="row border-bottom">
            <div className="col-lg-7 col-md-12">
              <div className="industrial-body ">
                {/* <h6 className="title-clr">Welcome to osiyatech</h6> */}
                <h2 className="all-title">API Integration Services</h2>
                {/* <div className='underline'>
              <p className='circle-line'></p>
              </div> */}

                <p className="text">
                  Third-party API integration enables your product to perform
                  the same functions as the program it integrates with - hence
                  functionality that could have taken months to build can
                  otherwise take only a few hours. It not only saves time and
                  money but also adds to the comfort of customers familiar with
                  the existing app.
                </p>

                <h2 className="all-title"> What are APIs? And what is a third-party API?</h2>
                <p  className="text">
                  {" "}
                  APIs are reusable communication interfaces between two apps
                  that enable seamless, and regulated data sharing. In the case
                  of third-party APIs, the owner develops app functionality and
                  the integration code is made public along with documentation
                  concerning its implementation. The connection is maintained by
                  special developer keys used for this purpose. In this way,
                  other apps can integrate it into their features.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 d-flex align-items-center justify-content-center">
              <div className="about-us-right-img  payment-img">
                <img src={Image.paymentRight} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us">
        <div className="container">
          <div className="why-choose-us-title">
            <h2 className="all-title mb-5">
              We can also consume data from a wide range of third-party sources
              from diverse domains.
            </h2>
          </div>
          <div className="why-choose-us-body">
            <div className="row  payment-section">
              <div className="col-lg-5 col-md-12 d-flex align-items-center justify-content-center">
                <div className="why-choose-us-left">
                  <img src={Image.paymentLeft} alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="why-we-choose-right">
                  <h2>Our API integration services include: </h2>
                  <ul>
                    <li> Social media</li>
                    <li> Payment gateways</li>
                    <li> SMS Gateway</li>
                    <li> Emails</li>
                    <li> Chat systems</li>
                    <li>Analytics</li>
                  </ul>

                  <h2>Key benefits of using a third-party API:</h2>
                  <ul>
                    <li>
                      {" "}
                      Efficiency: Rather than building complex functionalities
                      from scratch, you can access them from third parties,
                      saving time, effort, and cost.
                    </li>
                    <li>
                      {" "}
                      Less maintenance: It is easier to maintain since it is
                      owned and managed by the company that developed it.
                    </li>
                    <li>
                      {" "}
                      Ease of customization: Add the best possible features to
                      your app and expand your operational ease.
                    </li>
                    <li>
                      {" "}
                      Google Sign-in enables several apps to manage user
                      profiles using OAuth authorization credentials. Without
                      such authorization for third-party APIs, users will have
                      to create a new profile for every sign-up.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-clients-section">
        <div className="container">
          <div className="our-clients-body">
            <div className="title-body">
              <h2 className="all-title">Payment gateways</h2>
             
            </div>
            <div className="our-clients-row ">
              <div className="row">
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="sponser-img">
                    <img src={Image.paypalBox} />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="sponser-img">
                    <img
                      style={{ border: "1px solid #eee" }}
                      src={Image.AliPay}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="sponser-img">
                    <img src={Image.SpacespaceImage} />
                  </div>
                </div>
                
                 <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="sponser-img">
                    <img src={Image.KlarnaImage} />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="sponser-img">
                    <img src={Image.stripeBox} />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="sponser-img">
                    <img src={Image.authorizeNet} />
                  </div>
                </div>

               <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="sponser-img">
                    <img src={Image.AmazonPay} />
                  </div>
                </div> 
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="sponser-img">
                    <img src={Image.nmiBox} />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="sponser-img">
                    <img src={Image.inovio} />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="sponser-img">
                    <img src={Image.brainTree} />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="sponser-img">
                    <img src={Image.merchantEsolution} />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="sponser-img">
                    <img src={Image.WorldPay} />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="sponser-img">
                    <img src={Image.PaySafe} />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="sponser-img">
                    <img src={Image.GooglePay} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentGateway;
